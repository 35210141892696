import React from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import AppLoader from "components/AppLoader";
import ChargerCard from "components/ChargerCard";
import {__, messages} from "library/langs";
import axios from "library/request";

const MainListView = ({appFilter}) => {
  const [list, setList] = React.useState([]);
  const [cities, setCities] = React.useState([]);
  const [types, setTypes] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [meta, setMeta] = React.useState(null);
  const [fetching, setFetching] = React.useState(true);

  const fetchAll = () => {
    setFetching(true);
    const params = {
      ...(appFilter.filter ? appFilter.filter : {}),
      page
    }
    return axios.get('/charger', {params})
      .then(data => {
        setList(data?.result || []);
        setMeta(data.meta);
        setFetching(false);
      }).catch(error => {
        setList([]);
        setFetching(false);
      })
  }

  React.useEffect(() => {
    setCities([
      ...messages.enums?.['App\\Enums\\Cities']
    ])

    setTypes([
      ...messages.enums?.['App\\Enums\\ChargerType']
    ])
  }, [])

  React.useEffect(() => {
    fetchAll();
  }, [page, appFilter]);

  return (
    <React.Fragment>
      {
        !fetching && !!list.length &&
        <div className="flex w-full flex-col gap-4">
          {
            list.map((item, index) => (
              <React.Fragment key={ `charger-card-${ index }` }>
                {
                  !!index && index % 5 === 0 &&
                  (
                   <div className="ads p-3 overflow-x-hidden w-full flex justify-center items-center max-xxs:w-[15rem] max-xs:w-[22rem] min-xs:w-[20rem] max-sm:w-96 max-w-[450px] mx-auto">
                     <amp-ad
                       layout="fixed"
                       width="280"
                       height="280"
                       type="adsense"
                       class='max-xxs:w-[15rem] max-xs:w-[22rem] min-xs:w-[20rem] max-sm:w-96 max-w-[450px] mx-auto w-[100%]'
                       data-ad-client="pub-5645746035333435"
                       data-ad-slot="1805444760">
                     </amp-ad>
                   </div>
                  )
                }
                <ChargerCard types={ types }
                             index={ index }
                             urlPath={ `/charger/${ item.id }` }
                             onDeleteCallBack={ () => fetchAll() } item={ item } cities={ cities }
                />
              </React.Fragment>
            ))
          }

          {
            meta?.total_pages > 1 &&
            <div
              className="max-sm:w-96 max-w-[450px] flex justify-between item-center w-full px-3 my-3 text-sm text-sky-600 mx-auto">
              <button className='justify-center w-1/2 py-3 disabled:opacity-50'
                      onClick={ () => setPage(1 + page) }
                      disabled={ !meta.next_page }>
                <div className="flex items-center justify-start w-full">
                  <i className='icon-arrow-left2 rtl:rotate-180'/>
                  <span className='mx-2'> { __('Next') }</span>
                </div>
              </button>
              <button className='flex items-center justify-center w-1/2 py-3 disabled:opacity-50'
                      onClick={ () => setPage(page - 1) }
                      disabled={ !meta.prev_page }>
                <div className="flex items-center justify-end w-full">
                  <span className='mx-2'>{ __('Prev') }</span>
                  <i className='icon-arrow-right2 rtl:rotate-180'/>
                </div>
              </button>
            </div>
          }
        </div>
      }


      {
        !fetching && !list?.length &&
        <div className="flex flex-col items-center justify-center flex-grow py-16 mt-20">
          <div className="p-4 text-center">
            <h1 className="mb-4 text-2xl font-bold text-gray-800">{ __('No chargers found') }</h1>
            <p className="mb-4 text-sm text-gray-600">
              {
                __("Sorry, we couldn't find any chargers based on your search criteria, Help us improve by suggesting locations where chargers are needed")
              }
            </p>
            <Link to="/create"
                  className="inline-block px-4 py-2 mb-4 text-xs text-white rounded-md bg-emerald-600 hover:bg-emerald-800">
              { __('Suggest a new charger location') }
            </Link>
            <p className="text-xs text-gray-500">
              { __("Can not find what you are looking for? Request to add a charger") }
            </p>
            <Link to={ '/' } onClick={ () => window.location.reload() }
                  className="text-xs text-gray-500">
              { __("Back To Home") }
            </Link>


          </div>
        </div>
      }

      {
        fetching &&
        <AppLoader/>
      }
    </React.Fragment>
  )
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

const mapStateToProps = ({appFilter}) => ({
  appFilter
});

export default connect(mapStateToProps, mapDispatchToProps)(MainListView);
