import Compressor from "compressorjs";

export default {
    async compressImage(image) {
        return await new Promise((resolve, reject) => {
            new Compressor(image, {
                quality: 0.7, // Adjust the desired image quality (0.0 - 1.0)
                maxWidth: 600, // Adjust the maximum width of the compressed image
                maxHeight: 600, // Adjust the maximum height of the compressed image
                mimeType: "image/jpeg", // Specify the output image format
                success(result) {
                    resolve(result);
                },
                error(error) {
                    reject(error);
                },
            });
        });
    },

    async convertFileToBlob(file) {
        return await new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                resolve(event.target.result)
            };
            reader.onerror = (error) => {
                reject(error)
            };
            reader.readAsDataURL(file);
        })
    }
}