const Locations = {
  getUserLocation() {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(({coords}) => {
          resolve({
            lat: coords.latitude,
            lng: coords.longitude
          })
        }, (error) => {
          reject(error);
        });
      } else {
        reject("Geolocation not supported");
      }
    })
  },

  async getIpAddress() {
    return new Promise((resolve, reject) => {
      fetch("https://api.ipify.org/?format=json")
        .then((response) => response.json())
        .then(response => {
          if (response.ip) {
            return resolve({
              ip_address: response.ip
            });
          }

          fetch("https://geolocation-db.com/json/")
            .then((response) => response.json())
            .then(response => {
              resolve({
                ip_address: response.IPv4
              });
            })
        })
        .catch(async () => {
          fetch("https://geolocation-db.com/json/")
            .then((response) => response.json())
            .then(response => {
              resolve({
                ip_address: response.IPv4
              });
            })
        })
    })
  },

  googleColor: {
    'google-blue 100': `#4285F4`,
    'google-blue-dark 100': `#61a0bf`,  // ADDED
    'google-blue-light 100': `#1bb6ff`, // ADDED
    'white 100': `rgb(255, 255, 255)`,
  },

  defaultMapOptions : {
    zoomControl: false,
    streetViewControl: false,
    mapTypeControl: true,
    fullscreenControl: false,
    rotateControl: false,
  },

  viewMapOptions : {
    zoomControl: false,
    streetViewControl: false,
    mapTypeControl: false,
    fullscreenControl: false,
    rotateControl: false,
  },

  convertDistanceToKelMeter(distanceInRadians) {
    return distanceInRadians;
  },

  calculateRadius({center, bounds}) {
    const {lat: latCenter, lng: lngCenter} = center;
    const {north, east} = bounds;

    // Convert degrees to radians
    const toRadians = (degrees) => degrees * (Math.PI / 180);

    // Haversine formula to calculate the great-circle distance between two points
    const R = 6371; // Earth's radius in kilometers
    const dLat = toRadians(north - latCenter);
    const dLng = toRadians(east - lngCenter);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRadians(latCenter)) *
      Math.cos(toRadians(north)) *
      Math.sin(dLng / 2) *
      Math.sin(dLng / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c;

    return distance < 1 ? 1 : distance;
  },

  calculateDistance(center, marker) {
    const R = 6371; // Radius of the Earth in kilometers

    // Extract latitude and longitude from the objects
    const lat1 = center.lat;
    const lon1 = center.lng;
    const lat2 = marker.lat;
    const lon2 = marker.lng;

    // Convert degrees to radians
    const radLat1 = lat1 * (Math.PI / 180);
    const radLon1 = lon1 * (Math.PI / 180);
    const radLat2 = lat2 * (Math.PI / 180);
    const radLon2 = lon2 * (Math.PI / 180);

    // Differences
    const deltaLat = radLat2 - radLat1;
    const deltaLon = radLon2 - radLon1;

    // Haversine formula
    const a = Math.sin(deltaLat / 2) ** 2 +
      Math.cos(radLat1) * Math.cos(radLat2) *
      Math.sin(deltaLon / 2) ** 2;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    // Distance in kilometers
    const distance = R * c;

    return distance.toFixed(3);
  }
}

export default Locations
