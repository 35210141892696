import {APIProvider} from '@vis.gl/react-google-maps';
import GoogleMapWrapper from "components/GoogleMapWrapper";
import React from 'react';
import ReactDOM from 'react-dom/client';
import {HelmetProvider} from "react-helmet-async";
import {BrowserRouter} from "react-router-dom";
import App from './app';
import './index.css';
import reportWebVitals from './reportWebVitals';


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <APIProvider apiKey={ process.env.REACT_APP_MAP_KEY }>
    <GoogleMapWrapper>
      <BrowserRouter>
        <HelmetProvider>
          <App/>
        </HelmetProvider>
      </BrowserRouter>
    </GoogleMapWrapper>
  </APIProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
