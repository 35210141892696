let messages = {
};

const setMessages = ({messages: appMessages, lang, dir}) =>{
    messages ={
        ...appMessages,
        ...appMessages.pages
    }

    document.documentElement.lang = lang;
    document.documentElement.dir = dir;
}

const __ = (key = '', replace) => {
    let trans = '';

    if (!key) {
        return '';
    }

    const keyList = key.split('.');
    keyList.forEach(item => {
        trans = trans[item] || messages[item] || item
    })

    if (!!replace) {
        const keys = Object.keys(replace);
        const values = Object.values(replace);
        keys.forEach((item, index) => {
            trans = trans.replace(`:${ item }`, values?.[index]);
        })
    }

    return trans
}

export {
    __,
    messages,
    setMessages
}
