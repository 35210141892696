import FBApp from "library/firebase";
import React, {useEffect} from 'react';
import {Link, useNavigate} from "react-router-dom";
import {__} from 'library/langs.js';
import Config from 'library/config.js';

const NotFound = () => {
    const [appName] = React.useState(Config.PROJECT_NAME);
    const navigate = useNavigate();
    const {logEvents} = FBApp;

    useEffect(() => {
        if (window.location.pathname === `/home/${ window.lang }`) {
            navigate('/');
        }
    }, []);

    const handleEvent = ()=>{
        logEvents(`404_page_open_home_page`)
    }

    return (
        <div className="flex flex-col items-center justify-center min-h-full bg-white">
            <div className="text-center p-4">
                <h1 className="text-4xl md:text-6xl font-bold text-gray-800">{ appName }</h1>
                <h2 className="text-xl md:text-3xl text-gray-600 mt-4">{__('404 - Page Not Found')}</h2>
                <p className="text-gray-600 my-4">
                    {__('Oops! The page you are looking for does not exist')}
                </p>
                <Link to="/"
                      onClick={handleEvent}
                      className="inline-block bg-emerald-600 hover:bg-emerald-800 text-white py-2 px-4 rounded-md">
                    {__('Go back to :appName', {'appName':appName})}
                </Link>
            </div>
        </div>
    );
};

export default NotFound;
