import {FORM} from "enums/form";

const initState = {
  formData: {},
};

const createChargerForm = (state = initState, action) => {
  switch (action.type) {
    case FORM.CREATE_FORM_VALUES:
      return {...state, formData: action.formData};

    default:
      return state;
  }
};

export default createChargerForm;
