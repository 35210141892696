import {getAnalytics, logEvent} from "firebase/analytics";

// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getAuth} from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: process.env["REACT_APP_ANALYTICS_API_KEY"],
  authDomain: process.env["REACT_APP_ANALYTICS_AUTH_DOMAIN"],
  projectId: process.env["REACT_APP_ANALYTICS_PROJECT_ID"],
  storageBucket: process.env["REACT_APP_ANALYTICS_STORAGE_BUCKET"],
  messagingSenderId: process.env["REACT_APP_ANALYTICS_MESSAGE_SENDER_ID"],
  appId: process.env["REACT_APP_ANALYTICS_APP_ID"],
  measurementId: process.env["REACT_APP_ANALYTICS_MEASUREMENT_ID"],
};
// Initialize Firebase
const FirebaseApp = initializeApp(firebaseConfig);
const Analytics = () => getAnalytics(FirebaseApp);
const Auth = getAuth(FirebaseApp);
const logEvents = (message) => {
  if(process.env.NODE_ENV === "development"){
    return
  }
  logEvent(Analytics(), message)
};

export default {
  Analytics,
  FirebaseApp,
  Auth,
  logEvents,
  firebaseConfig
}
