import {Map, Marker} from "@vis.gl/react-google-maps";
import _ from 'lodash'
import React from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import Slider from "react-slick";
import ChargerMarkerIcon from 'assets/icons/charger-map-marker.svg'
import defaultChargerImage from "assets/images/defaultChargerImage.png";
import AppLoader from "components/AppLoader";
import GoogleMap from "components/AppLoader";
import {__} from "library/langs";
import Locations from "library/locations";
import axios from "library/request";
import sliderLib from "library/slider";
import Storage from "library/storage";
import Text from "library/text";

const MainMapView = ({appFilter}) => {
  const [list, setList] = React.useState([]);
  const [center, setCenter] = React.useState(null);
  const [userLocation, setUserLocation] = React.useState(null);
  const [mapZoom, setMapZoom] = React.useState(13);
  const [fetching, setFetching] = React.useState(true);
  const [isMapCards, setIsMapCards] = React.useState(false);
  const [oldParams, setOldParams] = React.useState('');
  const dir = Storage.get('USER_LANG') === 'en'?'ltr':'rtl';
  const sliderRef = React.useRef();

  const fetchDebounce = React.useCallback(_.debounce((params) => {
    fetchAll(params)
  }, 1000), []);

  const fetchAll = (mapFilter) => {
    setFetching(true);
    const filter = {
      ...appFilter?.filter,
      ...mapFilter
    }
    const params = {
      ...filter,
      page: 1,
      perPage: 50
    }

    if (_.isEqual(JSON.stringify(params), oldParams)) {
      return;
    }
    setOldParams(JSON.stringify(params));

    return axios.get('/charger', {params})
      .then(({result}) => {
        setList(result || []);
        if (!result?.length) {
          setIsMapCards(false)
        }
      }).catch(error => {
        setList([]);
      }).finally(() => {
        setTimeout(() => {
          setFetching(false);
        }, 500)
      })
  }

  React.useEffect(() => {
    Locations.getUserLocation().then((data) => {
      setUserLocation({...data})
      setCenter({...data})
    }).catch(() => {
      setCenter({lat: 31.963, lng: 35.930})
    }).finally(() => {
      fetchDebounce();
    })
  }, [])

  const handleOnMarkerCLiked = (item) => {
    setIsMapCards(true);
    setTimeout(() => {
      const card = document.querySelector(`#mapCard${ item.id }`);
      // const index = card?.getAttribute('data-index') || 0;
      const index = card.closest('.slick-slide').getAttribute('data-index')
      sliderRef.current.slickGoTo(index);
    }, 500)
  }

  const handleCameraChange = (data) => {
    setMapZoom(data.zoom);
    fetchDebounce({
      ...data.detail.center,
      radius: Locations.calculateRadius(data.detail),
    });
  }

  const handleOnDrag = (mapProps) => {
    setCenter({
      lat: mapProps.map.center.lat(),
      lng: mapProps.map.center.lng()
    });
  }

  const mapOptions = {
    zoomControl: false,
    streetViewControl: false,
    mapTypeControl: true,
    fullscreenControl: false,
    rotateControl: false,
  };

  React.useEffect(() => {
    fetchAll();
  }, [appFilter?.filter])

  return (
    <div className="flex flex-col relative">
      {
        !!fetching && <AppLoader/>
      }
      {
        !!center && <Map
          key={`googlemap`}
          className='map-view'
          defaultCenter={ {...center} }
          defaultZoom={ 13 }
          zoom={ mapZoom }
          gestureHandling={ 'greedy' }
          onCameraChanged={ handleCameraChange }
          onDragend={ handleOnDrag }
          options={ mapOptions }
        >
          {
            list.map((item, index) => (
              <Marker key={ `marker-key-${ index }` }
                      icon={ {
                        url: ChargerMarkerIcon,
                        scaledSize: new window.google.maps.Size(35, 35), // Adjust the size as needed
                      } }
                      onClick={ () => handleOnMarkerCLiked(item) }
                      position={ {lat: parseFloat(item.lat), lng: parseFloat(item.lng)} }
              />
            ))
          }
          {
            !!userLocation && <Marker
              icon={ {
                fillColor: Locations.googleColor['google-blue 100'],
                fillOpacity: 1,
                path: google?.maps?.SymbolPath?.CIRCLE,
                scale: 8,
                strokeColor: Locations.googleColor['white 100'],
                strokeWeight: 3,
              } }
              position={ {...userLocation} }/>
          }
        </Map>

      }

      {
        !!isMapCards &&
        <div className="map-view-cards">
          <div className="flex w-full">
            <button
              onClick={ () => setIsMapCards(false) }
              className='flex justify-center items-center bg-white rounded-full shadow-lg p-3 text-sm mb-1 mx-2'>
              <i className='icon-cancel-circle'/>
            </button>
          </div>
          <Slider { ...sliderLib.mapCardsSettings } ref={ sliderRef } className='w-full'>
            {
              [...list].map((item, index) => (
                <div
                  key={ `charger-card-key-${ index }` }
                  id={ `mapCard${ item.id }` }
                  data-index={ index }
                  dir={dir}
                  className="flex-col flex h-[264px] bg-white w-[140px] rounded p-2 gap-1 shadow-lg">
                  <img src={ item?.images?.[0]?.source || defaultChargerImage } alt={ item.name }
                       className='card-image w-full h-[120px] object-cover'/>
                  <div
                    className='item-text text-xs mt-1 text-gray-500'>{ __(`It is :distance km away`, {distance: Locations.calculateDistance(center, item)}) }</div>
                  <div className='item-text text-xs text-gray-500'>{ item.name }</div>
                  <Link to={ `/charger/${ item.id }` }
                        className='flex w-full item-text text-md min-h-[43px] text-[#1a73e8]'>{ Text.ellipses(item.title, 25) }</Link>
                  <div className="flex my-1 flex-wrap gap-1">
                    {
                      item.types.map((charger, tIndex) => (
                        <div className="flex map-card-type" key={ `type-${ tIndex }` }>
                          <i className='icon-power-cord text-[#1a73e8] pe-1 text-xs'/>
                          <span className='text-xs'>
                            { charger.label }
                          </span>
                        </div>
                      ))
                    }
                  </div>

                </div>
              ))
            }
          </Slider>
        </div>
      }
    </div>
  )
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

const mapStateToProps = ({appFilter}) => ({
  appFilter
});

export default connect(mapStateToProps, mapDispatchToProps)(MainMapView);
