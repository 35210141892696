import ChargerCard from "components/ChargerCard.jsx";
import Config from "library/config";
import FBApp from "library/firebase";
import {__} from "library/langs";
import axios from "library/request";
import React from "react";
import {Helmet} from "react-helmet-async";
import {Link, useNavigate, useParams} from 'react-router-dom';
import {toast} from "react-toastify";

const View = () => {
  const [item, setItem] = React.useState(null);
  const [cities, setCities] = React.useState([]);
  const [types, setTypes] = React.useState([]);
  const [isFetching, setIsFetching] = React.useState(false);
  const navigate = useNavigate();
  let {id} = useParams();
  const {logEvents} = FBApp;

  const fetchCharger = () => {
    setIsFetching(true);

    return axios.get(`/charger/${ id }`)
      .then((data) => {
        setItem(data?.result);
        setCities(data?.cities);
        setTypes(data?.types);
        setIsFetching(false);
        logEvents(`view_charger ${id}`)
      })
      .catch((error) => {
        setItem(null);
        setCities([]);
        setTypes([]);
        toast.error(error?.message || __('Something went wrong'));
        setIsFetching(false);
        logEvents(`failed_view_charger ${id}`)
      });
  }

  const onDeleteCallBack = () => {
    navigate('/')
  }

  React.useEffect(() => {
    if(!id){
      return;
    }
    fetchCharger();
  }, [id]);

  return (
    <div className='flex flex-col'>
      {
        !isFetching &&
        <Helmet>
          <meta charSet="utf-8"/>
          <title>{ Config.PROJECT_NAME } | { item?.title || 'Charger View' }</title>
          {
            item?.images?.[0]?.source &&
            <meta name="image" content={ item?.images?.[0]?.source }/>
          }
          <meta itemProp="name" content={ item?.title || 'Charger View' }/>
          <meta itemProp="description" content={ item?.description || 'Charger Description' }/>
          <meta itemProp="location" content={ item?.address || 'Charger Address' }/>
        </Helmet>
      }

      <div className="flex flex-col gap-4 my-5 max-w-md mx-auto px-1 w-[100%]">
        {
          isFetching &&
          <div
            className="flex p-8 rounded-xl bg-white bg-opacity-25 fixed flex-col justify-center items-center m-auto left-0 right-0 top-0 bottom-0 w-64 h-64">
            <div className="relative">
              <div className="w-12 h-12 bg-emerald-500 rounded-full bg-opacity-50"/>
              <div
                className="w-12 h-12 bg-emerald-500 rounded-full absolute top-0 left-0 animate-ping bg-opacity-50"/>
              <div className="w-12 h-12 bg-emerald-500 rounded-full absolute top-0 left-0 animate-pulse"/>
            </div>
          </div>
        }

        {
          !isFetching && item && <ChargerCard
            key={ `card-1 ` }
            types={ types }
            index={ '1' }
            onDeleteCallBack={ onDeleteCallBack }
            item={ item }
            cities={ cities }
            isFull
          />
        }

        { !isFetching && !item &&
        (
          <div className="max-w-md w-full p-6 rounded-lg text-center">
            <h1 className="text-xl font-bold mb-4">{ __('Charger Not Found') }</h1>
            <p className="text-sm text-gray-600 mb-6">
              { __('The charger you are looking for may have been removed or changed') }
            </p>

            <div className="flex justify-center space-x-4 mb-8">
              <Link to="/" className="border border-gray-200 font-normal py-2 px-4 rounded text-sm">
                { __('Back to Home') }
              </Link>
              <Link to="/create"
                    className="bg-emerald-600 text-white font-normal py-2 px-4 rounded text-sm">
                { __('Add Charger Location') }
              </Link>
            </div>

            <p className="text-xs text-gray-500">
              { __('If you believe this is an error, please contact support') }
            </p>
          </div>
        )
        }
      </div>
    </div>
  )
};

export default View;
