import {__, messages} from 'library/langs.js';
import React from "react";

const ChargerTypeInput = ({index, form, updateValue, deleteType, type}) => {
  const [types, setTypes] = React.useState([]);

  const [typeForm, setTypeForm] = React.useState({
    type: null,
    capacity: 100,
    count: 1,
  });

  const setValue = (value) => {
    setTypeForm({
      ...typeForm,
      ...value
    })
  }

  React.useEffect(() => {
    form.types[index] = typeForm;
    const types = form.types;
    updateValue({
      ...form,
      types
    })
  }, [typeForm]);

  React.useEffect(() => {
    if (!!type) {
      setTypeForm({...typeForm,...type})
    }
  }, [type])

  React.useEffect(() => {
    setTypes([
      ...messages.enums?.['App\\Enums\\ChargerType']
    ])
  }, [])

  return (
    <div className="flex gap-2 border px-2 py-3" key={ `item-${ index }-input` }>
      <select name="type" className='border w-full py-1 px-2 rounded'
              value={ typeForm?.type }
              onChange={ ({target}) => setValue({type: target.value}) }
      >
        <option value={ null }>{ __('Select Type') }</option>
        {
          types?.map((type, index) =>
            <option value={ index } key={ `type-${ index }` }>{ type }</option>
          )
        }

      </select>

      <input type="number" placeholder='Capacity' name='capacity' value={ typeForm?.capacity }
             className='border w-[100px] py-1 px-2 rounded'
             min={ 100 }
             onInput={ ({target}) => setValue({capacity: target.value}) }/>
      <span className={ 'w-[50px] w-10 py-1 px-1' }>KW</span>

      <input type="number" placeholder='Count' name='count' value={ typeForm?.count }
             className='border w-[70px] py-1 px-2 rounded'
             min={ 1 }
             onInput={ ({target}) => setValue({count: target.value}) }/>

      {
        !!deleteType &&
        <div className='flex flex-col justify-center' onClick={ () => deleteType() }>
          <i className='icon-bin text-red-400'/>
        </div>
      }
    </div>
  )

};

export default ChargerTypeInput
