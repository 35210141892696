import React, {useEffect} from "react";
import {__} from 'library/langs.js';
import Config from 'library/config';


const Footer = () => {
    const [year, setYear] = React.useState(2024);
    const [appName] = React.useState(Config.PROJECT_NAME);

    useEffect(()=>{
        setYear((new Date()).getFullYear());
    }, [])
    return (
        <div className="flex w-full h-[50px] mt-5 px-2 py-4 justify-between items-center max-w-4xl mx-auto w-full">
            <div className="flex w-full justify-end items-end text-xs text-gray-500 pe-3">
                {__('© :year :appName, All rights reserved', {year, appName})}
            </div>
        </div>
    )
};

export default Footer;
