import React from "react";
import Splash from './Splash';

const GoogleMapWrapper = ({children, ...reset}) => {
  const [isMapReady, setIsMapReady] = React.useState(false);
  React.useEffect(() => {
    // createIndexScript().then(() => {
    //   setTimeout(()=>{
    //     setIsMapReady(true);
    //   },200)
    // })

    setTimeout(()=>{
      setIsMapReady(true);
    },200)
  }, []);

  const createIndexScript = () => {
    return new Promise(async (resolve, reject) => {
      // const listOfScripts = [...document.getElementsByTagName('script')];
      // listOfScripts.forEach((element)=>{
      //   if(element['src'].indexOf(`key=${ process.env.REACT_APP_MAP_KEY }`)){
      //     element.remove();
      //   }
      // });
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.defer = 'defer';
      script.src = `https://maps.googleapis.com/maps/api/js?key=${ process.env.REACT_APP_MAP_KEY }&libraries=places&callback=Function.prototype`
      await document.body.appendChild(script);
      return resolve(true)
    })

  }


  return (
    <React.Fragment>
      {
        !!isMapReady ? children : <Splash />
      }
    </React.Fragment>

  )
}

export default GoogleMapWrapper
