import React from "react";
import {Link, useNavigate} from "react-router-dom";
import Logo from 'assets/icons/logo.png';
import Config from "library/config";
import {__, setMessages} from 'library/langs.js';
import axios from "library/request.js";
import Storage from "library/storage.js";

const Header = () => {
    const [isMenu, setIsMenu] = React.useState(false);
    const [appName] = React.useState(Config.PROJECT_NAME);
    const navigate = useNavigate();

    const hasUserInfo = !!localStorage.getItem('USER_SESSION')
    const closeMenu = () => {
        setIsMenu(false)
    }

    const handleOnLogOut = async (e) => {
        e.preventDefault();
        await axios.post('/auth/logout');
        Storage.remove('USER_SESSION', null);
        closeMenu();
    }

    const switchLang = () => {
        let currentLang = Storage.get('USER_LANG') || 'ar';
        let lang = 'ar';
        if (currentLang === 'ar') {
            lang = 'en';
        }

        Storage.set('USER_LANG',lang);
        axios.get(`/messages`, {params: {lang}})
            .then(async (data) => {
                await setMessages(data);
                closeMenu();
                navigate(0)
            })
    };


    const MainMenu = React.useMemo(() => {
        return (
            <div className="fixed top-0 left-0 flex w-full h-full p-4 flex-col bg-white z-20">
                <div className="flex flex-col w-full max-w-lg mx-auto">
                    <div className="flex justify-end">
                        <i className="icon-cancel-circle p-3 text-2xl font-semibold color-gray-400 cursor-pointer"
                           onClick={ closeMenu }
                        />
                    </div>

                    <div className="flex flex-col w-full">
                        <Link to="/" onClick={ closeMenu }
                              className="flex text-xl font-medium p-3 border-b border-b-gray-300">
                            { __('Main') }
                        </Link>
                        <Link to="/favorites" onClick={ closeMenu }
                              className="flex text-xl font-medium p-3 border-b border-b-gray-300">
                            { __('Favorites') }
                        </Link>
                        <Link to="/create" onClick={ closeMenu }
                              className="flex text-xl font-medium p-3 border-b border-b-gray-300">
                            { __('Add Charger') }
                        </Link>
                        {
                            !!hasUserInfo &&
                            <React.Fragment>
                                <Link to="/requested/update" onClick={ closeMenu }
                                      className="flex text-xl font-medium p-3 border-b border-b-gray-300">
                                    { __('Requested Update') }
                                </Link>
                                <Link to="/requested/create" onClick={ closeMenu }
                                      className="flex text-xl font-medium p-3 border-b border-b-gray-300">
                                    { __('Requested Create') }
                                </Link>

                            </React.Fragment>
                        }

                        <Link to="/about" onClick={ closeMenu }
                              className="flex text-xl font-medium p-3 border-b border-b-gray-300">
                            { __('About') }
                        </Link>

                        {
                            !hasUserInfo &&
                            <Link to="/login" onClick={ closeMenu }
                                  className="flex text-xl font-medium p-3 border-b border-b-gray-300">
                                { __('Login') }
                            </Link>
                        }

                        {
                            !!hasUserInfo &&
                            <Link to="#" onClick={ handleOnLogOut }
                                  className="flex text-xl font-medium p-3 border-b border-b-gray-300">
                                { __('Log Out') }
                            </Link>
                        }

                        <Link onClick={ switchLang } className="flex text-xl font-medium p-3">
                            { __('عربي') }
                        </Link>
                    </div>
                </div>
            </div>
        )

    }, [isMenu])
    return (
        <div className="flex w-full h-[50px] px-2 py-4 justify-between items-center max-w-4xl mx-auto border-b">
            <Link to={ '/' }>
                <div className="flex justify-center items-center">
                    <div className="flex w-[25px] me-2">
                        <img src={ Logo } alt="" className='w-full h-full' title={ appName }/>
                    </div>
                    <span className='text-muted text-md font-bold'>{ appName }</span>
                </div>
            </Link>
            <div className="flex">
                <i
                    onClick={ () => setIsMenu(true) }
                    className='icon-menu text-2xl font-bold p-2 color-[#525252] cursor-pointer'/>
            </div>
            { isMenu && MainMenu }
        </div>
    )
};

export default Header;
