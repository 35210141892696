const AppLoader = ()=>{
    return (
        <div className="fixed top-0 bottom-0 left-0 right-0 flex flex-col items-center justify-center w-screen h-screen p-8 m-auto bg-white bg-opacity-25 rounded-xl z-50 pointer-events-none	">
            <div className="relative">
                <div className="w-12 h-12 bg-opacity-50 rounded-full bg-emerald-500"/>
                <div className="absolute top-0 left-0 w-12 h-12 bg-opacity-50 rounded-full bg-emerald-500 animate-ping"/>
                <div className="absolute top-0 left-0 w-12 h-12 rounded-full bg-emerald-500 animate-pulse"/>
            </div>
        </div>
    )
}
export default AppLoader;
