import {Map, Marker} from "@vis.gl/react-google-maps";
import defaultChargerImage from "assets/images/defaultChargerImage.png";
import ReviewsForm from "components/ReviewsForm";
import Favorites from 'library/favorites.js';
import FBApp from "library/firebase";
import {__} from 'library/langs.js';
import Locations from "library/locations";
import Numbers from 'library/numbers.js';
import axios from "library/request.js";
import sliderLib from "library/slider.js";
import React from "react";
import {Link} from "react-router-dom";
import Slider from "react-slick";
import {toast} from "react-toastify";

const ChargerCard = ({item, cities, types, onDeleteCallBack, index, isFull, urlPath}) => {
  const sliderRef = React.useRef();
  const hasUserInfo = !!localStorage.getItem('USER_SESSION')
  const [isFavorite, setIsFavorite] = React.useState(false);
  const [reviewForm, setReviewForm] = React.useState(false);
  const [LatLngLiteral] = React.useState();
  const {logEvents} = FBApp;
  const moveSlideNext = () => {
    sliderRef.current.slickNext();
    logEvents('card_next_image');
  }

  const moveSlidePrev = () => {
    sliderRef.current.slickPrev();
    logEvents('card_prev_image');
  }

  const handleOnDelete = () => {
    const isConfirmed = window.confirm(__('Are you sure to delete this?'));
    if (!isConfirmed) {
      return
    }
    return axios.delete(`/charger/${ item.id }`)
      .then(() => {
        logEvents(`card_delete ${item.id }`);
        if (onDeleteCallBack) {
          onDeleteCallBack(item.id, index);
        }

      })
  }

  const setViewerCount = async () => {
    await axios.put(`/viewer/charger/${ item.id }`);
  };

  const copyToClipBoard = () => {
    navigator.clipboard.writeText(document.location.href).then(function () {
      toast.success(__('Page URL coped to clipboard'));
      logEvents(`copy_to_clip_board ${item.id }`);
    }, function () {
      //
    });
  }

  const AddToFavorite = async () => {
    const isFave = await Favorites.AddChargerToFavorite(item.id)
    setIsFavorite(isFave);
    logEvents(`add_to_favorite ${item.id }`);
  }

  React.useEffect(() => {
    if (isFull && item?.id) {
      setViewerCount();
    }

    setIsFavorite(!!Favorites.isChargerFavorite(item.id))
  }, [isFull, item])

  const openMapLink = (event) => {
    event.preventDefault();
    logEvents(`open_map_link ${item.id }`);
    window.open(item.map_link, '_blank');
  }

  return (
    <div
      className={ `border rounded p-3 overflow-x-hidden max-xxs:w-[15rem] max-xs:w-[22rem] min-xs:w-[20rem] max-sm:w-96 max-w-[450px] mx-auto w-[100%]` }
      data-id={ item?.id }>
      <div className="flex w-full h-[200px] overflow-hidden rounded pb-3 ">
        {
          !!item?.images?.length &&
          <div className='card-slider relative h-[200px] w-[100%] max-w-full overflow-hidden'>
            {
              item?.images?.length > 1 &&
              <div className="slider-action slide-prev icon-circle-left"
                   onClick={ moveSlidePrev }
              />
            }

            <Slider { ...sliderLib.settings } ref={ sliderRef } key={ item['images']?.length }>
              {
                item.images?.map((image, imgIndex) => (
                  <Link key={ `${ index }-${ imgIndex }-image` }
                        to={ urlPath }
                        className='w-full h-full overflow-hidden flex justify-content-center'>
                    <img
                      src={ image.url ? `https://storage.googleapis.com/envoicify/${ image.url }` : image.source }
                      alt={ image.name }
                    />
                  </Link>
                ))
              }
            </Slider>
            {
              item?.images?.length > 1 &&
              <div className="slider-action slide-next icon-circle-right"
                   onClick={ moveSlideNext }
              />
            }

          </div>
        }


        {
          !item['images']?.length &&
          <img src={ defaultChargerImage } alt="No Image"
               className={ 'object-cover h-full w-full' }/>
        }
      </div>
      <div className="flex flex-col py-3">
        <Link to={ urlPath } className="text-xs font-medium text-gray-700">{ item['name'] }</Link>
        <Link to={ urlPath }
              className={ `text-xl font-bold mb-1 text-${ urlPath ? '[#1a73e8]' : 'gray-700' }` }>{ item['title'] }</Link>
        <Link to={ urlPath } className="text-sm text-gray-500 leading-3">{ item['sub_title'] }</Link>
        <a className="text-sm text-gray-700 flex items-center mt-2" href={ item?.map_link } target='_blank'>
          <i className='icon-location text-[#1a73e8] pe-2 text-sm'/>
          { cities[item['city']] } { item['address'] }
        </a>

        {
          isFull &&
          <a href={ (item?.phone ? `tel:${ item.phone }` : '#') }
             className="text-sm text-gray-700 flex items-center mt-1">
            <i className='icon-mobile text-[#1a73e8] pe-2 text-sm'/>
            <span dir='ltr' className='font-semibold text-gray-500'>
              { item['phone'] ?? __('No Number') }
            </span>
          </a>
        }

        {
          !!isFull && !!item.is_24_hour &&
          <Link to={ urlPath } className="text-sm text-gray-700 flex items-center mt-1">
            <i className='icon-clock text-[#1a73e8] pe-2 text-sm'/>
            <span className='text-emerald-600 '>{ __('Open 24 Hour') }</span>
          </Link>
        }

        {
          isFull &&
          <div className="border-t border-t-gray-300 w-full mt-4 mb-3"/>
        }

        {
          isFull &&
          <div className="flex gap-3 justify-evenly items-baseline px-2 w-full mb-1 cursor-pointer">
            <div className="flex flex-col items-center">
              <span className='text-xs font-bold text-[#1a73e8] h-8 flex items-center'>{ __('Views') }</span>
              <span
                className='font-semibold drop-shadow-md  text-xs flex h-4 items-center'>{ Numbers.formatNumberViews(item?.views?.count || 0) || '' }</span>
            </div>
            <div className="flex flex-col items-center" onClick={ copyToClipBoard }>
              <span className='text-xs font-bold text-[#1a73e8] h-8 flex items-center'>{ __('Copy Link') }</span>
              <i className='icon-share drop-shadow-md text-green-600 text-sm flex h-4 items-center'/>
            </div>
            <a href={ item?.map_link } target='_blank' className="flex flex-col items-center">
              <span className='text-xs font-bold text-[#1a73e8] h-8 flex items-center'>{ __('Direction') }</span>
              <i className='icon-compass drop-shadow-md text-blue-600 text-sm flex h-4 items-center'/>
            </a>
            <div className="flex flex-col items-center" onClick={ AddToFavorite }>
              <span className='text-xs font-bold text-[#1a73e8] h-8 flex items-center'>{ __('Favorite') }</span>
              <i
                className={ `icon-bookmark drop-shadow-md text-sm flex h-4 items-center ${ isFavorite ? 'text-red-600' : "" }` }/>
            </div>
            <div onClick={ () => setReviewForm(true) }
                 className="flex flex-col items-center">
              <span className='text-xs font-bold text-[#1a73e8] h-8 flex items-center'>{ __('Review') }</span>
              <i className={ `icon-star-full text-amber-400 text-sm drop-shadow-md flex h-4 items-center` }/>
            </div>
          </div>
        }

        {
          isFull &&
          <div className="border-t border-t-gray-300 w-full mt-4 mb-3"/>
        }

        <Link to={ urlPath } className="flex flex-col mt-2">
          { item.types?.map((type, typeIndex) => (
            <div className="flex justify-between items-center" key={ `type-${ typeIndex }` }>
              <div className='flex items-center'>
                <i className='icon-power-cord text-[#1a73e8] pe-3 text-sm'/>
                <span className='pe-1 font-smiebold text-gray-700 text-sm'>{ types[type.type] }</span>
                <span className='inline-flex items-center px-2 text-gray-400'>.</span>
                <span className='text-gray-400 text-sm'>{ type.capacity }&nbsp;{ type.unit }</span>
              </div>

              <div className="flex bg-emerald-200	text-emerald-600 rounded text-xs px-1">
                <span>{ type.count }</span>
                /<span>{ type.count }</span>
              </div>
            </div>
          )) }
        </Link>

        {
          isFull &&
          <div className="border-t border-t-gray-300 w-full mt-4 mb-3"/>
        }
        {
          !!isFull &&
          <div className='inline'>
            <div className="text-sm text-gray-700 flex items-center mt-1">
              <p className='text-emerald-900 whitespace-pre-line'>{ item.description || __('No Description') }</p>
            </div>
            <div className="border-t border-t-gray-300 w-full mt-4 mb-3"/>
          </div>
        }
      </div>
      {
        isFull &&
        <div className='w-full h-25 max-w-md mx-auto mb-4'
             onClick={ openMapLink }
        >
          <Map
            className='show-map-view'
            center={ {
              lat: parseFloat(item.lat),
              lng: parseFloat(item.lng)
            } }
            zoom={ 14 }
            options={ Locations.viewMapOptions }
            gestureHandling={ 'none' }
          >
            <Marker
              position={ {
                lat: parseFloat(item.lat),
                lng: parseFloat(item.lng)
              } }
            />
          </Map>
        </div>
      }

      <div className='my-2 py-2 flex justify-between items-center'>
        <div className='inline'>
          {
            !!hasUserInfo &&
            <button
              className='text-xs bg-red-100 border border-red-300 py-2 px-2 rounded '
              title={ __('Delete') }
              onClick={ () => handleOnDelete(item['id']) }
            >
              { __('Delete') }
            </button>
          }
        </div>

        <Link
          to={ `/charger/${ item.id }/edit` }
          title={ __('Request Update') }
          className='text-xs bg-emerald-100 border border-emerald-300 py-2 px-2 rounded '
        >
          { __('Request Update') }
        </Link>
      </div>

      {
        !!reviewForm &&
        <ReviewsForm id={ item.id } onHide={ () => setReviewForm(false) }/>
      }

      {
        isFull &&
        <div id="reviews">
          <div className="border-t border-t-gray-300 w-full mt-4 mb-3"/>
          <div className="flex w-full text-md font-bold my-4">
            { __('Charger Station Reviews') }
          </div>
          <div className="flex flex-col w-full gap-3">
            {
              !item?.reviews?.length &&
              <div className=" w-full my-4">
                <div className="rounded-lg">
                  <div
                    className="text-xs text-gray-500 text-center inline">
                    { __('No reviews yet، Be the first one to add a review!') }.&nbsp;
                  </div>
                  <div
                    onClick={ () => setReviewForm(true) }
                    className='text-xs text-emerald-600 cursor-pointer inline'>
                    { __('Review Now') }
                  </div>
                </div>
              </div>
            }
            {
              item?.reviews?.map((review, rIndex) => (
                <div
                  className={ `flex flex-col w-full px-2 mb-3 pb-2 ${ rIndex >= item?.reviews.length - 1 ? '' : 'border-b border-b-gray-200' }` }
                  key={ `review-${ rIndex }` }>
                  <div className="text-bold text-md capitalize mb-1">
                    { review.title }
                  </div>

                  <div className="w-full flex mb-2">
                    <div className="flex gap-1">
                      {
                        [...Array(5).keys()].map((rate, rateIndex) =>
                          <i
                            key={ `rate-${ rateIndex }` }
                            className={ `drop-shadow text-xs ${ rate < review.rate ? 'icon-star-full text-amber-400' : 'text-gray-500 icon-star-empty' }` }/>
                        )
                      }
                    </div>
                  </div>
                  <div className="text-sm text-gray-500 whitespace-pre-line">
                    { review.description }
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      }
    </div>
  )
}
export default ChargerCard
