import React from "react";
import {Link, useNavigate} from "react-router-dom";
import AppLoader from "components/AppLoader";
import DateApp from 'library/date';
import {__} from 'library/langs';
import axios from "library/request";

/**
 * Requested Update
 * @return {JSX.Element}
 * @constructor
 */
const RequestedUpdate = () => {
  const [list, setList] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [meta, setMeta] = React.useState(null);
  const [fetching, setFetching] = React.useState(true);
  const navigate = useNavigate();

  const fetchAll = () => {
    setFetching(true);
    const params = {
      page
    }
    return axios.get('/charger/request/list', {params})
      .then(data => {
        setList(data.result || []);
        setMeta(data.meta);
        setFetching(false);
      }).catch(error => {
        setList([]);
        setFetching(false);
      })
  }

  const openRequest = (id) => {
    navigate(`/requested/${id}`);
  }

  React.useEffect(() => {
    fetchAll();
  }, [page]);

  return (
    <div className='flex flex-col'>
      <div className="flex flex-col gap-4 my-5 w-fit w-full mx-auto px-4 justify-center items-center">
        {
          fetching &&
          <AppLoader/>
        }
        <table className='w-full max-w-full border-collapse border border-gray-300'>
          <thead>
          <tr className="bg-gray-200">
            <th className="border border-gray-300 px-4 py-2">{ __('Name') }</th>
            <th className="border border-gray-300 px-4 py-2">{ __('Requests Count') }</th>
            <th className="border border-gray-300 px-4 py-2">{ __('Created At') }</th>
          </tr>
          </thead>

          <tbody>
          {
            !fetching && list.map((item, index) => (
              <tr key={ `updated-card-${ index }` } onClick={ () => openRequest(item.id) }>
                <td className="border border-gray-300 px-4 py-2">{ item.name }</td>
                <td
                  className="border border-gray-300 px-4 py-2 text-center">{ item?.update_requests?.length || '-' }</td>
                <td className="border border-gray-300 px-4 py-2"
                    dir='ltr'>{ DateApp.getFromNow(item?.update_requests?.[item?.update_requests.length - 1]?.created_at, true) || '-' }</td>
              </tr>
            ))
          }
          </tbody>
        </table>


        {
          !fetching && !list?.length &&
          <div className="flex flex-col items-center justify-center mt-20 py-16 flex-grow">
            <div className="text-center p-4">
              <h1 className="text-2xl font-bold text-gray-800 mb-4">{ __('No chargers found') }</h1>
              <p className="text-gray-600 mb-4 text-sm">
                {
                  __("Sorry, we couldn't find any chargers based on your search criteria, Help us improve by suggesting locations where chargers are needed")
                }
              </p>
              <Link to="/create"
                    className="text-xs inline-block bg-emerald-600 hover:bg-emerald-800 text-white py-2 px-4 rounded-md mb-4">
                { __('Suggest a new charger location') }
              </Link>
              <p className="text-xs text-gray-500">
                { __("Can not find what you are looking for? Request to add a charger") }
              </p>
              <Link to={ '/' } className="text-xs text-gray-500">
                { __("Back To Home") }
              </Link>


            </div>
          </div>
        }
      </div>
      {
        !fetching && meta?.total_pages > 1 &&
        <div
          className="max-sm:w-96 max-w-[450px] flex justify-between item-center w-full px-3 my-3 text-sm text-sky-600 mx-auto">
          <button className='py-3  w-1/2 disabled:opacity-50 justify-center'
                  onClick={ () => setPage(1 + page) }
                  disabled={ !meta.next_page }>
            <div className="w-full flex justify-start items-center">
              <i className='icon-arrow-left2 rtl:rotate-180'/>
              <span className='mx-2'> { __('Next') }</span>
            </div>
          </button>
          <button className='py-3 w-1/2 disabled:opacity-50 flex justify-center items-center'
                  onClick={ () => setPage(page - 1) }
                  disabled={ !meta.prev_page }>
            <div className="w-full flex justify-end items-center">
              <span className='mx-2'>{ __('Prev') }</span>
              <i className='icon-arrow-right2 rtl:rotate-180'/>
            </div>
          </button>
        </div>
      }
      {
        fetching &&
        <div
          className="flex p-8 rounded-xl bg-white bg-opacity-25 fixed flex-col justify-center items-center m-auto left-0 right-0 top-0 bottom-0 w-64 h-64">
          <div className="relative">
            <div className="w-12 h-12 bg-emerald-500 rounded-full bg-opacity-50"/>
            <div
              className="w-12 h-12 bg-emerald-500 rounded-full absolute top-0 left-0 animate-ping bg-opacity-50"/>
            <div className="w-12 h-12 bg-emerald-500 rounded-full absolute top-0 left-0 animate-pulse"/>
          </div>
        </div>
      }

    </div>
  );
};

export default RequestedUpdate;
