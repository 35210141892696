import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import axios from "library/request.js";
import {__} from 'library/langs.js';

const Login = () => {
    const [form, setForm] = useState({email: '', password: ''});
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const setValues = (value) => {
        setForm({
            ...form,
            ...value
        });
    }

    const onSubmit = () => {
        setIsLoading(true);
        axios.post('/auth/login ', {
            email: form.email,
            password: form.password
        })
            .then((data) => {
                storeUserInfo(data);
                setIsLoading(false);
                navigate('/');
            })
            .catch((error) => {
                setIsLoading(false);
                toast.error(error?.message || error || 'something went wrong');
            })
    }

    const storeUserInfo = (data) => {
        const session = {
            ...data?.result,
            email: form.email
        }
        localStorage.setItem('USER_SESSION', JSON.stringify(session))
    }

    const hasUserInfo = () => {
        return !!localStorage.getItem('USER_SESSION')
    }


    // logged In User Redirect to Home
    React.useEffect(()=>{
        if(hasUserInfo()){
            navigate('/');
        }
    }, [])

    return (
        <div className="flex mx-w-md flex-col justify-center items-center px-4 w-full">
            <div className="flex flex-col rounded-xl border border-gray-200 p-8 mx-auto w-full mt-12">
                <div className="flex flex-col p-2">
                    <label htmlFor="EmailInput" className='text-sm text-bold mb-1'>
                        {__('Email')}
                    </label>
                    <input type="email"
                           autoFocus
                           onChange={ ({target}) => setValues({email: target.value}) }
                           placeholder={__('Email')} className="py-2 px-3 border border-gray-200"/>
                </div>
                <div className="flex flex-col p-2 mt-3">
                    <label htmlFor="PasswordInput" className='text-sm text-bold mb-1'>
                        {__('Password')}
                    </label>
                    <input type="password"
                           onChange={ ({target}) => setValues({password: target.value}) }
                           placeholder={__('Password')} className="py-2 px-3 border border-gray-200"/>
                </div>

                <div className="flex flex-col p-2 mt-3">
                    <button
                        disabled={ isLoading }
                        onClick={ onSubmit }
                        className='my-2 p-2 w-full rounded bg-emerald-600 text-white mb-3 flex justify-center items-center disabled:opacity-75'>
                        <span className='me-2'>{__('Login')}</span>
                        {
                            isLoading &&
                            <div
                                className="inline-block h-4 w-4 animate-spin rounded-full border-2 border-solid border-current border-e-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                                role="status">
                            </div>
                        }
                    </button>
                </div>
            </div>
        </div>
    )
};

export default Login
