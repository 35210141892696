import Config from "library/config";
import AppLogo from 'assets/icons/logo.png';
import React from 'react';


const Splash = () => {
  return (
    <div className="flex fixed w-screen top-0 z-50 bg-white left-0 right-0 h-screen">
      <div className="flex flex-col max-w-md w-full h-full mx-auto">
        <div className="flex overflow-hidden max-w-[150px] max-h-[190px] w-full h-full mx-auto mt-24">
          <img src={ AppLogo } alt="ChargifyJo" className='w-full h-full'/>
        </div>
        <span className='text-center text-xl font-bold text-emerald-800 mt-2'>{ Config.PROJECT_NAME }</span>
      </div>
    </div>
  )
};

export default Splash;
