import React from "react";
import {Link, useNavigate, useParams} from 'react-router-dom';
import {toast} from "react-toastify";
import ChargerTypeInput from "components/ChargerTypeInput";
import UploadImagesInput from "components/UploadImages";
import DateApp from "library/date";
import {__} from "library/langs";
import axios from "library/request";

const formData = {}

const View = () => {
  const [item, setItem] = React.useState({});
  const [cities, setCities] = React.useState({});
  const [defaultCharger, setDefaultCharger] = React.useState({});
  const [types, setTypes] = React.useState({});
  const [form, setForm] = React.useState(null);
  const [images, setImages] = React.useState([]);
  const [isSaving, setIsSaving] = React.useState(false);
  const [typesItems, setTypesItems] = React.useState([]);
  const [errors, setErrors] = React.useState([]);
  let {id} = useParams();
  const navigate = useNavigate();
  let typesKey = 1;


  React.useEffect(() => {
    fetchSetting();
    fetchCharger()
  }, [id])

  const setValue = (value) => {
    setForm({
      ...form,
      ...value
    });
  }

  const fetchSetting = () => {
    axios.get('/charger/create').then(async data => {
      setCities(data?.result?.cities || []);
      setTypes(data?.result?.types || [])
    }).catch(error => {
      toast.error(error?.message || 'Something went wrong')
    })
  }

  const fetchCharger = () => {
    axios.get(`request/charger/${ id }/view`)
      .then((data, index) => {
        setDefaultCharger(data?.result);
      })
      .catch((error) => {
        setDefaultCharger({});
      })
  }

  const selectRequest = (request) => {
    setForm({
      ...request
    });

    setTypesItems([
      ...request?.types
    ])

    setImages([...images.flatMap(image => image?.source || image)])
  }

  const handleOnDeleteType = (index) => {
    typesItems.splice(index, 1);
    setTypesItems([
      ...typesItems
    ]);

    setValue({
      types: typesItems
    })
  }

  const submitForm = () => {
    setIsSaving(true);
    const data = {
      ...form,
      images
    }
    setErrors([]);
    return axios.put(`/request/${ form?.id }/charger/${ defaultCharger.id }`, data)
      .then(async data => {
        toast.success(data.message);
        navigate(`/charger/${ defaultCharger?.id }`);
      }).catch(error => {
        setErrors([...Object.values(error.errors)]);
        toast.error(error?.message || __('Something went wrong'));
      }).finally(() => {
        setTimeout(() => {
          setIsSaving(false);
        }, 2000)
      })
  }

  return (
    <div className='flex flex-col'>
      <div className="flex flex-col gap-9 my-5 max-w-xl mx-auto px-2">

        <div className='max-h-[300px] overflow-auto'>
          <table className='min-w-full bg-white border border-gray-300'>
            <thead>
            <tr>
              <th
                className='px-6 py-3 border-b-2 border-gray-300 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider'>
                { __('Name') }
              </th>
              <th
                className='px-6 py-3 border-b-2 border-gray-300 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider'>
                { __('Title') }
              </th>
              <th
                className='px-6 py-3 border-b-2 border-gray-300 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider'>
                { __('Create At') }
              </th>
              <th
                className='px-6 py-3 border-b-2 border-gray-300 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider'/>
            </tr>
            </thead>
            <tbody>
            {
              defaultCharger.update_requests?.map((request, rIndex) => (
                <tr className="hover:bg-gray-50" key={ `request-row-${ rIndex }` }
                    onClick={ () => selectRequest(request) }>
                  <td className='px-6 py-4 border-b border-gray-300 text-xs text-gray-700'>{ request.name }</td>
                  <td className='px-6 py-4 border-b border-gray-300 text-xs text-gray-700'>{ request.title }</td>
                  <td
                    className='px-6 py-4 border-b border-gray-300 text-xs text-gray-700'>{ DateApp.getFromNow(request.created_at, false) }</td>
                  <td className='px-6 py-4 border-b border-gray-300 text-xs text-gray-700'>
                    <button>
                      <i className=' icon-bin text-red-500'/>
                    </button>
                  </td>
                </tr>
              ))
            }
            </tbody>
          </table>
        </div>

        {
          !!form &&
          <div className="flex flex-col gap-3 overflow-x-hidden">
            <div className="flex w-full flex-col gap-2">
              <input type="text" placeholder={ __('Name') } name='name' value={ form.name }
                     className='border w-full py-1 px-2 rounded'
                     onInput={ ({target}) => setValue({name: target.value}) }/>
              <span className='border border-gray-200 my-1 mb-2 p-2 bg-gray-100 rounded text-xs'>
              { defaultCharger.name }
            </span>
            </div>

            <div className="flex w-full flex-col gap-2">
              <input type="text" placeholder={ __('Title') } name='title' value={ form.title }
                     className='border w-full py-1 px-2 rounded'
                     onInput={ ({target}) => setValue({title: target.value}) }/>
              <span className='border border-gray-200 my-1 mb-2 p-2 bg-gray-100 rounded text-xs'>
              { defaultCharger.title }
            </span>
            </div>

            <div className="flex w-full flex-col gap-2">
              <input type="text" placeholder={ __('Sub Title') } name='sub_title' value={ form.sub_title }
                     className='border w-full py-1 px-2 rounded'
                     onInput={ ({target}) => setValue({sub_title: target.value}) }/>
              <span className='border border-gray-200 my-1 mb-2 p-2 bg-gray-100 rounded text-xs'>
              { defaultCharger.sub_title }
            </span>
            </div>

            <div className="flex w-full flex-col gap-2">
              <input type="text" placeholder={ __('Phone') } name='phone' value={ form.phone }
                     className='border w-full py-1 px-2 rounded'
                     onInput={ ({target}) => setValue({phone: target.value}) }/>
              <span className='border border-gray-200 my-1 mb-2 p-2 bg-gray-100 rounded text-xs'>
              { defaultCharger.phone }
            </span>
            </div>

            <div className="flex w-full flex-col gap-2">
              <label htmlFor="is_24_hour" className="flex py-1 px-2  items-center justify-start border w-full rounded">
                <input type="checkbox"
                       className='me-3' id='is_24_hour'
                       checked={ !!form.is_24_hour }
                       onChange={ ({target}) => setValue({is_24_hour: target.checked}) }/>
                <span>{ __('Is Open 24 Hour?') }</span>

              </label>
              <span className='border border-gray-200 my-1 mb-2 p-2 bg-gray-100 rounded text-xs'>
              <input type="checkbox"
                     className='me-3 disabled pointer-event-none'
                     checked={ defaultCharger.is_24_hour }
              />
              <span>{ __('Is Open 24 Hour?') }</span>
            </span>
            </div>


            <div className="flex w-full flex-col gap-2">
              {
                !!cities?.length &&
                <select name="city" className='border w-full py-1 px-2 rounded'
                        value={ form.city }
                        onChange={ ({target}) => setValue({city: target.value}) }
                >
                  <option>{ __('Select City') }</option>
                  {
                    cities?.map((city, index) => <option value={ city.id }
                                                         key={ `city-${ index }` }
                      >
                        { city.value }
                      </option>
                    )
                  }

                </select>
              }
              <span className='border border-gray-200 my-1 mb-2 p-2 bg-gray-100 rounded text-xs'>
              <span>{ cities[defaultCharger.city]?.value }</span>
            </span>
            </div>

            <div className="flex px-1 py-1 font-bold text-gray-500">
              { __('Charger Types') }
            </div>

            <div className="flex w-full flex-col gap-2" key={ `types-${ typesKey }` }>
              <div className="flex w-full flex-col mb-3">
                {
                  typesItems?.map((item, typeIndex) => (
                    <div className='flex w-full flex-col'>
                      <ChargerTypeInput types={ types }
                                        form={ form }
                                        type={ item }
                                        key={ `item-type-${ typeIndex }` }
                                        deleteType={ () => handleOnDeleteType(typeIndex) }
                                        index={ typeIndex }
                                        updateValue={ setValue }
                      />
                    </div>
                  ))
                }

                <button className={ 'border bg-primary p-2 font-semibold' }
                        onClick={ () => setTypesItems([...typesItems, []]) }>
                  { __('Add Types') }
                </button>
              </div>
              <div className="flex flex-col w-full">
                <div className="flex w-full border-b border-b-100 p-1">
                  <span className='w-1/2 text-xs ps-1'>{ __('Type') }</span>
                  <span className='w-1/5 text-xs'>{ __('Capacity') }</span>
                  <span className='w-1/5 text-xs'>{ __('Count') }</span>
                </div>
                { defaultCharger?.types?.map((dType, dIndex) => (
                  <div className="flex w-full border-b border-b-100 p-1" key={ `d-type-${ dIndex }` }>
                    <span className='w-1/2 text-xs ps-1'>{ types[dType.type]?.value }</span>
                    <span className='w-1/5 text-xs'>{ dType.capacity }</span>
                    <span className='w-1/5 text-xs'>{ dType.count }</span>
                  </div>
                )) }
              </div>
            </div>

            <div className="flex w-full flex-col gap-2">
              <input type="url" placeholder={ __('Map Link') } name='map_link' value={ form.map_link }
                     className='border w-full py-1 px-2 rounded'
                     onInput={ ({target}) => setValue({map_link: target.value}) }/>
              <span className='border border-gray-200 my-1 mb-2 p-2 bg-gray-100 rounded text-xs'>
              <span>{ defaultCharger.map_link }</span>
            </span>
            </div>

            <div className="flex w-full flex-col gap-2">
              <input type="url" placeholder={ __('Embed Link') } name='embed_link' value={ form.embed_link }
                     className='border w-full py-1 px-2 rounded'
                     onInput={ ({target}) => setValue({embed_link: target.value}) }/>
              <span
                className='border my-1 mb-2 p-2 bg-gray-100 rounded'>
               <input type="url" placeholder={ __('Embed Link') } disabled value={ form.embed_link }
                      className='w-full rounded border-gray-200 text-xs'/>
            </span>
            </div>

            <div className="flex w-full flex-col gap-2">
              <input type="text" placeholder={ __('Address') } name='address' value={ form.address }
                     className='border w-full py-1 px-2 rounded'
                     onInput={ ({target}) => setValue({address: target.value}) }/>
              <span className='border border-gray-200 my-1 mb-2 p-2 bg-gray-100 rounded text-xs'>
              <span>{ defaultCharger.address }</span>
            </span>
            </div>

            <div className="flex w-full flex-col gap-2">
              <div className="flex gap-3">
                <input type="text" placeholder='Lng' name='lng' value={ form.lng }
                       className='border w-full py-1 px-2 rounded'
                       onInput={ ({target}) => setValue({lng: target.value}) }/>
                <input type="text" placeholder='Lat' name='lat' value={ form.lat }
                       className='border w-full py-1 px-2 rounded'
                       onInput={ ({target}) => setValue({lat: target.value}) }/>
              </div>

              <div className='border flex gap-3 border-gray-200 my-1 mb-2 p-2 bg-gray-100 rounded text-xs'>
                <input type="text" placeholder='Lng' name='lng' value={ defaultCharger.lng }
                       className='border w-full py-1 px-2 rounded' disabled/>
                <input type="text" placeholder='Lat' name='lat' value={ defaultCharger.lat }
                       className='border w-full py-1 px-2 rounded' disabled/>
              </div>
            </div>

            <UploadImagesInput defaultImages={ form.images } setValues={ setImages }/>
            <div className="bg-gray-100 px-2">
              <UploadImagesInput defaultImages={ defaultCharger.images } isReadOnly/>
            </div>

            <div className="flex w-full flex-col gap-2">
          <textarea placeholder={ __('Description') }
                    name='description'
                    value={ form.description }
                    className='border w-full py-1 px-2 rounded'
                    rows={ 10 }
                    onInput={ ({target}) => setValue({description: target.value}) }/>
              <span className='border border-gray-200 my-1 mb-2 p-2 bg-gray-100 rounded text-xs mx-w-full'>
              <span>{ defaultCharger.description }</span>
            </span>
            </div>

            <button type="button"
                    disabled={ isSaving }
                    className='w-full p-2 border rounded bg-emerald-600' onClick={ submitForm }>
              <span className={ 'font-semibold text-muted text-white mx-2' }>{ __('Update') }</span>
              {
                !!isSaving &&
                <div
                  className="inline-block h-4 w-4 animate-spin rounded-full border-2 border-solid border-white border-e-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                  role="status">
                </div>
              }
            </button>

          </div>
        }


        { !item &&
        (
          <div className="max-w-md w-full p-6 rounded-lg text-center">
            <h1 className="text-xl font-bold mb-4">Charger Not Found</h1>
            <p className="text-sm text-gray-600 mb-6">
              The charger you are looking for may have been removed or changed.
            </p>

            <div className="flex justify-center space-x-4 mb-8">
              <Link to="/" className="border border-gray-200 font-normal py-2 px-4 rounded text-sm">
                Back to Home
              </Link>
              <Link to="/create"
                    className="bg-emerald-600 text-white font-normal py-2 px-4 rounded text-sm">
                Add Charger Location
              </Link>
            </div>

            <p className="text-xs text-gray-500">
              If you believe this is an error, please contact support.
            </p>
          </div>
        )
        }
      </div>
    </div>
  )
};

export default View
