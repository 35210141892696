import axios from "axios";
import Config from "./config";

const isProd = window.location.hostname !== 'localhost';
const baseURL = isProd ? Config.BASE_URL_PROD : Config.BASE_URL;

const request = axios.create({
    baseURL,
    headers: {
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': 'Origin, X-Requested-With, Content-Type, Accept',
        'Content-Type': 'application/json',
    }
});

request.interceptors.request.use(function (config) {
    if (!!localStorage.getItem('USER_SESSION')) {
        const session = JSON.parse(localStorage.getItem('USER_SESSION'));
        config.headers['Authorization'] = `${ session?.token_type } ${ session?.access_token }`;

    }

    config.headers['Language'] = localStorage.getItem('USER_LANG') || 'ar';
    document.cookie = `Language=${config.headers['Language']};max-age=604800;*`

    return config;
}, function (error) {
    return Promise.reject(error);
});

request.interceptors.response.use(function (response) {
    return response.data || response;
}, function (error) {
    return Promise.reject(error?.response?.data || error?.response || error);
});

export default request
