import {FILTER} from "enums/filter";

export const setFilter = (filter) => ({
  type: FILTER.APP_FILTER,
  filter,
});

export const doFilter = (formData) => async (dispatch) => {
  await dispatch(setFilter(formData));
};
