const Config = {
    PROJECT_NAME: "Chargify JO",
    BASE_URL_PROD: 'https://api.chargifyjo.com/api/',
    BASE_URL: 'http://localhost:8000/api/',
    SUPPORT_EMAIL: 'montaserfzy@gmail.com',
    SUPPORT_PHONE: '+962797590040',
    PROJECT_DESCRIPTION: "Discover electric vehicle (EV) charging stations across Jordan with Chargify, Find nearby stations, check availability, and plan your trips with ease, Chargify helps you navigate sustainable travel options, ensuring you're always powered up on your journeys, Start your eco-friendly adventure today!",
    PROJECT_KEYWORDS: "electric vehicle charging stations, EV chargers Jordan, charging points Jordan, Jordan electric car charging, EV charging infrastructure, sustainable travel Jordan, electric vehicle network Jordan, eco-friendly travel Jordan, charging station locator, Jordan EV charging map",
};

export default Config