import Images from "library/images.js";
import {__} from 'library/langs.js';
import SliderLib from 'library/slider.js';
import React from "react";
import Slider from "react-slick";

const UploadImagesInput = ({
                             defaultImages = [],
                             setValues = () => {},
                             isReadOnly = false
                           }) => {
  const [images, setImages] = React.useState([]);
  const [loader, setLoader] = React.useState(false);
  const sliderRef = React.useRef(null);

  const setFormImages = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (!event.target?.files?.length) {
      return
    }
    setImages([])
    setLoader(true);
    const listImages = [];
    Object.values(event.target.files).map(async file => {
      await Images.compressImage(file).then(async image => {
        await Images.convertFileToBlob(image).then(async result => {
          listImages.push(result);
        })
      }).catch(error => {
        setLoader(false);
      })
    });

    setTimeout(() => {
      setImages([...listImages])
      setValues([...listImages])
      setLoader(false);
    }, 2000);
  }

  const handleOnOpen = () => {
    document.getElementById('uploadInput').click()
  }

  React.useEffect(() => {
    if (defaultImages.length) {
      setImages([...defaultImages])
      setValues([...defaultImages.flatMap(image => image?.source || image)])
    }
  }, [defaultImages])

  const ImagesSliderMemo = React.useMemo(() => {
    return (
      images.length ? (
        <div className="flex flex-col w-full my-2 z-3 w-full" id={ `images-${ images?.length }` }>
          <div className="relative flex w-full h-[100px]">
            <Slider { ...SliderLib.ImageSliderSetting } ref={ sliderRef }
                    className='input-images w-full h-full'>
              {
                images.map((img, index) => (
                  <div className="flex relative" key={ `slide-image-${ index }` }>
                    <img src={ img?.source || img }
                         className={ 'w-[150px] h-[150px] px-1' }
                    />
                  </div>
                ))
              }
            </Slider>
          </div>
          {
            !isReadOnly && <div className="flex mt-10 p-2 cursor-pointer" onClick={ handleOnOpen }>
              { __('Replace All Images') }
            </div>
          }

        </div>
      ) : null
    )
  }, [images.length])

  return (
    <div className="flex flex-col">
      {
        !isReadOnly &&
        <div className="flex py-2 text-muted text-sm">
          { __('Upload Images') }
        </div>
      }

      <div className="flex w-full h-[200px] flex-col relative border border-dashed border-gray-400 rounded"
      >
        <input type="file"
               id='uploadInput'
               onChange={ setFormImages }
               multiple
               accept="image/jpeg, image/png, image/jpg"
               className={ `opacity-0 absolute top-0 right-0 w-full h-full ${ images.length ? 'w-0' : '' }` }
        />

        { ImagesSliderMemo }

        {
          !!loader &&
          <div className="absolute flex w-full h-full justify-center items-center">
            <div
              className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-primary motion-reduce:animate-[spin_1.5s_linear_infinite]"
              role="status">
                        <span
                          className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">Loading...</span>
            </div>
          </div>
        }
      </div>

    </div>
  )
}

export default UploadImagesInput;
