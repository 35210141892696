import React, {useState} from 'react';
import {Helmet} from 'react-helmet-async';
import {Provider} from "react-redux";
import {Route, Routes} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {applyMiddleware, compose, createStore} from "redux";
import {thunk} from "redux-thunk";
import Footer from './components/Footer.jsx';
import Header from './components/Header.jsx';
import Config from './library/config';
import {setMessages} from './library/langs';
import axios from "./library/request";
import Storage from "./library/storage";
import About from './pages/About';
import Create from './pages/Charger/Create';
import Update from './pages/Charger/Update';
import View from './pages/Charger/View';
import Favorites from './pages/Favorites';
import Main from './pages/Main';
import NotFound from './pages/NotFound';
import Login from './pages/Register/Login.jsx';
import RequestedCreate from './pages/Requested/Create';
import RequestedUpdateList from './pages/Requested/UpdatesList';
import RequestedView from './pages/Requested/View';
import Reducer from "./reducers";

window.messages = {};
window.messages['pages'] = {};

const App = () => {
  const [isFetching, setIsFetching] = useState(true);
  const setAppMessages = () => {
    setIsFetching(true);
    const lang = Storage.get('USER_LANG') || 'ar';
    axios.get('/messages', {params: {lang}}).then(async data => {
      await setMessages(data);
      setIsFetching(false)
    }).catch(() => {
      setIsFetching(false)
    })
  }

  const checkAuth = () => {
    if (!Storage.get('USER_SESSION')) {
      return;
    }

    axios.post('/auth/me').then(async data => {
      // do nothing
    }).catch(() => {
      Storage.remove('USER_SESSION', null);
    });
  }


  React.useEffect(() => {
    setAppMessages()
    checkAuth();
  }, [])

  let composeEnhancers = compose;

  if (process.env.NODE_ENV === "development") {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  }

  const store = createStore(Reducer, composeEnhancers(applyMiddleware(thunk)));

  return (
    <Provider store={ store }>
      <div className="flex-col flex min-h-screen min-w-[290px] w-full mx-auto overflow-x-hidden">
        <Helmet>
          <script async src="https://www.googletagmanager.com/gtag/js?id=G-T9T3EVNX45"></script>
          <script>
            {`window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
      
              gtag('config', 'G-T9T3EVNX45');`}
          </script>
          <title>{ Config.PROJECT_NAME }</title>
        </Helmet>

        {
          !isFetching && <React.Fragment>
            <Header/>
            <div className="mx-auto flex-grow max-w-lg min-w-[290px] overflow-x-hidden w-[100%]">
              <Routes>
                <Route path="/" element={ <Main/> }/>
                <Route path="/favorites" element={ <Favorites/> }/>
                <Route path="/charger/:id" element={ <View /> }/>
                <Route path="/charger/:id/edit" element={ <Update/> }/>
                <Route path="/login" element={ <Login/> }/>
                <Route path="/about" element={ <About/> }/>
                <Route path="/create" element={ <Create/> }/>
                <Route path="/requested/:id" element={ <RequestedView/> }/>
                <Route path="/requested/update" element={ <RequestedUpdateList/> }/>
                <Route path="/requested/create" element={ <RequestedCreate/> }/>
                <Route path="/*" element={ <NotFound/> }/>
              </Routes>
            </div>
            <Footer/>
          </React.Fragment>
        }
        <ToastContainer theme="colored" limit={ 2 } draggable closeOnClick position='top-center'/>
      </div>
    </Provider>
  );
};

export default App

