import {FILTER} from "enums/filter";

const initState = {
  filter: {},
};

const appFilter = (state = initState, action) => {
  switch (action.type) {
    case FILTER.APP_FILTER:
      return {...state, filter: action.filter};

    default:
      return state;
  }
};

export default appFilter;
