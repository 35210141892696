import moment from 'moment-timezone';

export default {
  getFormattedDateTime(date, format = 'YYYY-MM-DD HH:mm:ss'){
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return moment(date).tz(userTimezone).fromNow(true).format(format);
  },

  getFromNow(date, isWithoutSuffix = true){
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return moment(date).tz(userTimezone).fromNow(isWithoutSuffix);
  }
}
