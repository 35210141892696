import {setCreateFormData} from "actions/form";
import ChargerTypeInput from "components/ChargerTypeInput";
import MapSection from "components/Create/MapSection";
import UploadImagesInput from "components/UploadImages";
import {__, messages} from 'library/langs.js';
import axios from "library/request.js";
import _ from "lodash";
import React from "react";
import {connect, useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import FBApp from "library/firebase.js";

const formData = {
  'name': '',
  'title': '',
  'sub_title': '',
  'phone': '',
  'city': '',
  'map_link': '',
  'embed_link': '',
  'address': '',
  'lng': '',
  'lat': '',
  'description': '',
  'is_24_hour': false,
  'types': []
}
const Create = ({chargerForm}) => {
  const [form, setForm] = React.useState(formData);
  const [cities, setCities] = React.useState({});
  const [images, setImages] = React.useState([]);
  const [typesItems, setTypesItems] = React.useState([]);
  const [errors, setErrors] = React.useState([]);
  const [isSaving, setIsSaving] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {logEvents} = FBApp;

  const setValue = (value) => {
    setForm({
      ...form,
      ...value
    });
  }

  React.useEffect(() => {
    return  () => {
      setForm(formData);
      setImages([]);
      dispatch(setCreateFormData(null));
    }
  }, []);

  const store = () => {
    form.images = images
    setErrors([]);
    setIsSaving(true);
    return axios.post('/charger/store', form)
      .then(async data => {
        setForm(formData);
        setImages([]);
        toast.success(data.message);
        await dispatch(setCreateFormData(null));
        logEvents('create_charger_point')
        navigate(`/charger/${ data?.id }`);
      }).catch(error => {
        setErrors([...Object.values(error.errors)]);
        toast.error(error?.message || 'Something went wrong')
      }).finally(() => {
        setTimeout(() => {
          setIsSaving(false);
        }, 2000)
      })
  }

  const prepareSelectedPoint = () => {
    if (_.isEmpty(chargerForm?.formData)) {
      return;
    }
    setValue({
      ...chargerForm?.formData
    });
    setImages([...chargerForm?.formData?.images])
  }

  React.useEffect(() => {
    prepareSelectedPoint();
  }, [chargerForm])

  React.useEffect(() => {
    setCities([
      ...messages.enums?.['App\\Enums\\Cities']
    ])
  }, [])

  return (
    <div className='p-3 flex flex-col gap-5 text-sm' id='createPage'>
      <div className="flex flex-col">
        {
          errors?.map((error, errorIndex) => (
            <div key={ `error-${ errorIndex }` }
                 className="flex text-xs p-2 text-gray-700 border border-red-300 rounded bg-red-100 mb-2 ">
              { error }
            </div>
          ))
        }
      </div>

      <div className="flex w-full min-h-[300px] pb-6 border-b border-dashed border-b-gray-300">
        <MapSection/>
      </div>
      {
        !_.isEmpty(chargerForm?.formData) &&
        <div className="flex flex-col w-full gap-5" id='ChargerForm'>
          <input type="text" placeholder={ __('Name') } name='name' value={ form.name }
                 className='border w-full py-1 px-2 rounded'
                 onInput={ ({target}) => setValue({name: target.value}) }/>

          <input type="text" placeholder={ __('Title') } name='title' value={ form.title }
                 className='border w-full py-1 px-2 rounded'
                 onInput={ ({target}) => setValue({title: target.value}) }/>

          <input type="text" placeholder={ __('Sub Title') } name='sub_title' value={ form.sub_title }
                 className='border w-full py-1 px-2 rounded'
                 onInput={ ({target}) => setValue({sub_title: target.value}) }/>

          <input type="text" placeholder={ __('Phone') } name='phone' value={ form.phone }
                 className='border w-full py-1 px-2 rounded'
                 onInput={ ({target}) => setValue({phone: target.value}) }/>

          <label htmlFor="is_24_hour" className="flex py-1 px-2  items-center justify-start border w-full rounded">
            <input type="checkbox"
                   className='me-3' id='is_24_hour'
                   checked={ form.is_24_hour }
                   onChange={ ({target}) => setValue({is_24_hour: target.checked}) }/>
            <span>{ __('Is Open 24 Hour?') }</span>
          </label>

          {
            !!cities?.length &&
            <select name="city" className='border w-full py-1 px-2 rounded'
                    value={ form.city }
                    onChange={ ({target}) => setValue({city: target.value}) }
            >
              <option>{ __('Select City') }</option>
              {
                cities?.map((city, index) =>
                  <option value={ index } key={ `city-${ index }` }>{ city }</option>
                )
              }

            </select>
          }

          <div className="flex px-1 py-1 font-bold text-gray-500">
            { __('Charger Types') }
          </div>

          {
            typesItems.map((item, itemIndex) => (
              <ChargerTypeInput form={ form } key={ `item-type-${ itemIndex }` }
                                index={ itemIndex } updateValue={ setValue }/>
            ))
          }

          <button className={ 'border bg-primary p-2 font-semibold' }
                  onClick={ () => setTypesItems([...typesItems, []]) }>
            { __('Add Types') }
          </button>


          {/*<input type="url" placeholder={ __('Map Link') } name='map_link' value={ form.map_link }*/ }
          {/*       className='border w-full py-1 px-2 rounded'*/ }
          {/*       onInput={ ({target}) => setValue({map_link: target.value}) }/>*/ }

          {/*<input type="url" placeholder={ __('Embed Link') } name='embed_link' value={ form.embed_link }*/ }
          {/*       className='border w-full py-1 px-2 rounded'*/ }
          {/*       onInput={ ({target}) => setValue({embed_link: target.value}) }/>*/ }

          <input type="text" placeholder={ __('Address') } name='address' value={ form.address }
                 className='border w-full py-1 px-2 rounded'
                 onInput={ ({target}) => setValue({address: target.value}) }/>

          <div className="flex gap-3">
            <input type="text" placeholder='Lng' name='lng' value={ form.lng }
                   className='border w-full py-1 px-2 rounded'
                   onInput={ ({target}) => setValue({lng: target.value}) }/>
            <input type="text" placeholder='Lat' name='lat' value={ form.lat }
                   className='border w-full py-1 px-2 rounded'
                   onInput={ ({target}) => setValue({lat: target.value}) }/>
          </div>

          <UploadImagesInput defaultImages={ form.images } setValues={ setImages }/>

          <textarea placeholder={ __('Description') }
                    name='description'
                    value={ form.description }
                    className='border w-full py-1 px-2 rounded'
                    rows={ 10 }
                    onInput={ ({target}) => setValue({description: target.value}) }
          />

          <button type="button"
                  disabled={ isSaving }
                  className='w-full p-2 border rounded bg-emerald-600' onClick={ store }>
            <span className={ 'font-semibold text-muted text-white mx-2' }>{ __('Submit') }</span>
            {
              !!isSaving &&
              <div
                className="inline-block h-4 w-4 animate-spin rounded-full border-2 border-solid border-white border-e-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                role="status">
              </div>
            }
          </button>
        </div>
      }
    </div>
  )
}


const mapStateToProps = ({chargerForm}) => ({
  chargerForm
});

export default connect(mapStateToProps)(Create);
