import FBApp from "library/firebase";
import React, {useState} from "react";
import { useNavigate } from "react-router-dom";
import Filter from "components/Filter.jsx";
import MainMapView from "components/MainMapView";
import MainListView from "components/MainListView";
import { connect } from "react-redux";

const Main = () => {
  const [isMap, setIsMap] = useState(false);
  const [filter, setFilter] = useState({});
  const history = useNavigate();
  const {logEvents} = FBApp;

  const handleToggleView = () => {
    setIsMap(prevIsMap => {
      const newView = !prevIsMap;
      history(`?view=${newView ? 'map' : 'list'}`);
      logEvents(`main_view_switched ${newView ? 'map' : 'list'}`)
      return newView;
    });
  };

  React.useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const view = queryParams.get('view');
    setIsMap(view === 'map');
  }, []);


  return (
    <div className='flex flex-col'>
      <div className="flex flex-col items-center justify-center w-full gap-2 px-4 mx-auto my-5 w-fit">
        <Filter setFilter={ setFilter }/>
        <div className="max-sm:w-96 max-w-[450px] flex w-full cursor-pointer max-xs:px-3">
          <button className='me-2 p-2 flex items-center justify-center rounded border border-gray-300'
                  onClick={ () => handleToggleView() }>
            <i className={ !!isMap ? 'icon-map text-sky-700' : 'icon-map2' }/>
          </button>
        </div>
        {isMap && <MainMapView />}
        {!isMap && <MainListView filter={filter} />}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

const mapStateToProps = ({ AppFilter }) => {
  return {
    AppFilter,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
