import Storage from "library/storage.js";

const Favorites = {
    isChargerFavorite(chargerId) {
        let favList = Storage.get('FAVORITES') || [];
        return favList.findIndex(i => i === chargerId) !== -1;
    },

    async AddChargerToFavorite(chargerId) {
        let favList = Storage.get('FAVORITES') || [];
        let favIndex = await favList.findIndex(i => i === chargerId);
        let isFav = false;

        if (favIndex !== -1) {
            favList.splice(favIndex, 1);
            isFav = false;
        } else {
            favList.push(chargerId);
            isFav = true;
        }
        await Storage.set('FAVORITES', [...favList]);
        return isFav;
    },

    getUserListIds() {
        return Storage.get('FAVORITES') || [];
    }
}

export default Favorites;
