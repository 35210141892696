import {Map, Marker} from "@vis.gl/react-google-maps";
import {setCreateFormData} from "actions/form";
import ChargerMarkerIcon from "assets/icons/charger-map-marker.svg";
import {__, messages} from "library/langs";
import Locations from "library/locations";
import axios from "library/request";
import Storage from "library/storage";
import _ from "lodash";
import React from "react";
import {connect, useDispatch} from "react-redux";
import {toast} from "react-toastify";

const MapSection = ({chargerForm, formData = null}) => {
  const [list, setList] = React.useState([]);
  const [center, setCenter] = React.useState(null);
  const [userLocation, setUserLocation] = React.useState(null);
  const [mapZoom, setMapZoom] = React.useState(13);
  const [marker, setMarker] = React.useState(null);
  const [mapObject, setMapObject] = React.useState(null);
  const [cameraData, setCameraData] = React.useState(null);
  const [suggestionList, setSuggestionList] = React.useState([]);
  const [selectedPoint, setSelectedPoint] = React.useState(null);
  const dispatch = useDispatch();
  const language = Storage.get('USER_LANG') || 'ar';

  React.useEffect(() => {
    Locations.getUserLocation().then((data) => {
      setUserLocation({lat: data.lat, lng: data.lng});
      setCenter({lat: data.lat, lng: data.lng})
      fetchDebounce({
        lat: data.lat,
        lng: data.lng,
        radius: Locations.calculateRadius({lat: data.lat, lng: data.lng}),
      });
    }).catch(() => {
      setCenter({lat: 31.963, lng: 35.930})
    }).finally(() => {
      if (formData) {
        setCenter({lat: formData.lat, lng: formData.lng})
        setMarker({lat: formData.lat, lng: formData.lng})
      }
    })
    return () => {
      setUserLocation(null);
      setCenter(null);
      setMarker(null);
    }
  }, [])

  const fetchDebounce = React.useCallback(_.debounce((params) => {
    fetchAll(params)
  }, 1000), []);


  const handleCameraChange = (data) => {
    setMapZoom(data.zoom);
    setCameraData(data)
    fetchDebounce({
      ...data.detail.center,
      radius: Locations.calculateRadius(data.detail),
    });
  }


  const handleOnDrag = (mapProps) => {
    setCenter({
      lat: mapProps.map.center.lat(),
      lng: mapProps.map.center.lng()
    });
  }

  const handleMapClick = (event) => {
    const lat = event.detail.latLng.lat;
    const lng = event.detail.latLng.lng;
    setMarker({lat, lng});
    const map = new window.google.maps.Map(document.createElement('div')) || mapObject;
    const service = new window.google.maps.places.PlacesService(map);

    handleOnClearSelection();
    const request = {
      location: {lat, lng},
      radius: 300, // 300 meters
      type: ['establishment', "gas_station", "ev_charging_station"],
      language
    };

    service.nearbySearch(request, (results, status, ...rest) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        setSuggestionList(results);
      } else {
        toast.error(__('No stations within :meter Meter', {meter: request.radius}));
        setSuggestionList([]);
      }
    });
  };

  const handleOnSelectSuggestion = (event) => {
    event.preventDefault();
    const index = event.target.value;
    if (index < 0) {
      setSelectedPoint(null);
      return;
    }
    setSelectedPoint(suggestionList[index]);
    dispatch(setCreateFormData(null));
  }

  const handleOnClearSelection = () => {
    setSelectedPoint(null);
    if (document.getElementById('suggestion')) {
      document.getElementById('suggestion').value = -1;
    }
    dispatch(setCreateFormData(null));
  }

  const handleOnSetSelection = async () => {
    const map = new window.google.maps.Map(document.createElement('div')) || mapObject;
    const service = new window.google.maps.places.PlacesService(map);

    const requestPayload = {
      placeId: selectedPoint.place_id,
      language,
      fields: ['name', 'geometry', 'vicinity', 'formatted_phone_number', 'url', 'address_components', 'types', 'photos', 'opening_hours', 'formatted_address'],
    };

    service.getDetails(requestPayload, async (details, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK && details) {

        let title = `${ __('Charger Station') } ${ details.name }`;
        let sub_title = '';
        let city_label = details.address_components?.find(component => component.types.includes('locality'))?.long_name?.replace(/[\u064B-\u0652\u0653-\u065F]/g, '')
        let city = messages.enums?.['App\\Enums\\Cities']?.indexOf(city_label);
        let images = [];

        details?.address_components?.forEach(component => {
          if (component.types.includes('route') || component.types.includes("sublocality")) {
            sub_title = component.long_name;
          } else if (component.types.includes('premise')) {
            title = component.long_name;
          }
        });

        if (city === -1) {
          sub_title = city_label
        }

        if (details?.photos?.length) {
          images = details.photos?.map(photo => photo.getUrl())?.slice(0, 3)
        }

        const placeDetails = {
          sub_title,
          title,
          name: details.name,
          lat: details.geometry.location.lat(),
          lng: details.geometry.location.lng(),
          address: details.vicinity,
          is_24_hour: !!details.opening_hours?.isOpen(new Date()),
          phone: details.formatted_phone_number?.split(' ')?.join('')?.replace(/[()]/g, ''),
          map_link: details.url,
          embed_link: details.url,
          city_label,
          city,
          images,
        };
        await dispatch(setCreateFormData({...placeDetails}));
        setTimeout(() => {
          const top = document.getElementById('ChargerForm').offsetTop - 50;
          window.scrollTo({top, behavior: 'smooth'});
        }, 200)
      } else {
        await dispatch(setCreateFormData({}));
      }
    });
  }

  const fetchAll = (filter) => {
    const params = {
      ...filter,
      page: 1,
      perPage: 50
    }

    return axios.get('/charger', {params})
      .then(({result}) => {
        setList(result || []);
      }).catch(error => {
        setList([]);
      })
  }

  return (
    <div className='flex flex-col w-full gap-3'>
      <span className='text-xs text-gray-600 mb-2'>
        { __('Select Location On Map and then select the suggested Address from the dropdown and Accept hte selection and the you able to update the address details and store it') }
      </span>
      {
        !!center && <Map
          className='create-map-view'
          defaultCenter={ {...center} }
          defaultZoom={ 13 }
          zoom={ mapZoom }
          gestureHandling={ 'greedy' }
          onCameraChanged={ handleCameraChange }
          onDragend={ handleOnDrag }
          options={ Locations.defaultMapOptions }
          onClick={ handleMapClick }
          onLoad={ map => setMapObject(map) }
        >

          {
            list.map((item, index) => (
              <Marker key={ `marker-key-${ index }` }
                      icon={ {
                        url: ChargerMarkerIcon,
                        scaledSize: new window.google.maps.Size(35, 35), // Adjust the size as needed
                      } }
                      position={ {lat: parseFloat(item.lat), lng: parseFloat(item.lng)} }
              />
            ))
          }

          { marker && (
            <Marker
              position={ marker }
            />
          ) }

          {
            !!userLocation && <Marker
              icon={ {
                fillColor: Locations.googleColor['google-blue 100'],
                fillOpacity: 1,
                path: window.google?.maps?.SymbolPath?.CIRCLE || google?.maps?.SymbolPath?.CIRCLE,
                scale: 8,
                strokeColor: Locations.googleColor['white 100'],
                strokeWeight: 3,
              } }
              position={ {...userLocation} }/>
          }
        </Map>
      }

      {
        !!suggestionList?.length &&
        <div className="flex w-full px-2">
          <select name="suggestion" id="suggestion"
                  onChange={ handleOnSelectSuggestion }
                  className='p-2 border border-gray-200 text-sm w-full rounded'>
            <option value={ -1 }>{ __('Select Suggested Address') }</option>
            {
              suggestionList.map((item, index) => (
                <option value={ index } key={ `suggestion-item-${ index }` }>{ item.name }</option>
              ))
            }
          </select>
        </div>
      }

      {
        !!selectedPoint && (
          <div className="flex flex-col w-full gap-2">
            <div className="flex gap-2 w-full px-2">
              <input type="text" className='p-2 border border-gray-200 text-sm w-full rounded' placeholder='Lat'
                     defaultValue={ selectedPoint?.geometry?.location?.lat() }/>
              <input type="text" className='p-2 border border-gray-200 text-sm w-full rounded' placeholder='Lng'
                     defaultValue={ selectedPoint?.geometry?.location?.lng() }/>
            </div>

            <div className="flex gap-2 w-full px-2">
              <button
                onClick={ handleOnSetSelection }
                className='p-2 w-full bg-emerald-600 hover:bg-emerald-800 text-white rounded font-smiebold shadow-md hover:shadow'
              >
                { __('Select This Address') }
              </button>
              <button
                onClick={ handleOnClearSelection }
                className='p-2 w-full border border-gray-200 text-emerald-600 hover:text-emerald-800 bg-white rounded font-smiebold shadow-md hover:shadow'
              >
                { __('Clear Selection') }
              </button>
            </div>
          </div>
        )
      }

    </div>
  )
};


const mapStateToProps = ({chargerForm}) => ({
  chargerForm
});

export default connect(mapStateToProps)(MapSection);
