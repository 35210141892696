import React from "react";
import {connect, useDispatch} from "react-redux";
import {toast} from 'react-toastify';
import {doFilter} from "actions/filters";
import {__, messages} from 'library/langs.js';
import Locations from "library/locations.js";

const formData = {
  'city': '',
  'type': '',
  'lng': '',
  'lat': '',
  'text': '',
  'radius': '',
}

const Filter = ({setFilter, appFilter}) => {
  const [form, setForm] = React.useState(formData);
  const [showForm, setShowForm] = React.useState(false);
  const [isFetching, setIsFetching] = React.useState(false);
  const [isSearching, setIsSearching] = React.useState(false);
  const [cities, setCities] = React.useState([])
  const [types, setTypes] = React.useState([])
  const dispatch = useDispatch();

  const setValue = (value) => {
    setForm({
      ...form,
      ...value
    });
  }

  const setUserLocation = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsFetching(true);
    Locations.getUserLocation().then((data) => {
      setValue({...data, radius: 10})
    }).catch((error) => {
      toast.error(__(error?.message || error))
    }).finally((message) => {
      setIsFetching(false);
    })
  }

  const searchFilter = async () => {
    setIsSearching(true);
    await setFilter(form);
    await dispatch(doFilter({...form}));
    setShowForm(false);
    setIsSearching(false);
  }

  const resetFilter = async () => {
    setIsSearching(true);
    setForm(formData);
    await setFilter(formData);
    await dispatch(doFilter({}));
    setShowForm(false);
    setIsSearching(false);
  }

  const handleEnterClick = async (event) => {
    if (event.code === 'Enter') {
      await searchFilter(event)
    }
  }

  const validateRadius = (event) => {
    let value = parseInt(event.target.value, 10); // Parse the input value as an integer

    // Check if the value is NaN (Not a Number) or outside the range
    if (isNaN(value) || value < 1) {
      event.target.value = 1; // Set to minimum value if less than 1
    } else if (value > 100) {
      event.target.value = 100; // Set to maximum value if more than 100
    }
  }

  React.useEffect(() => {
    if (showForm) {
      document.querySelector('body').classList.add('page-filter')
    } else {
      document.querySelector('body').classList.remove('page-filter')
    }
  }, [showForm])

  React.useEffect(() => {
    setCities([
      ...messages.enums?.['App\\Enums\\Cities']
    ])

    setTypes([
      ...messages.enums?.['App\\Enums\\ChargerType']
    ])
  }, [])

  return (
    <div className="flex flex-col w-full relative bg-white justify-center items-center">
      <div className="max-sm:w-96 max-w-[450px] flex w-full cursor-pointer max-xs:px-3"
           onClick={ () => setShowForm(!showForm) }>
        <div className="flex border rounded border-gray-300 py-1 px-2 text-sm me-2 items-center">
          <i className='icon-search me-1'/>
          <span className='me-1'>{ __('Search') }</span>
        </div>
        <div className="flex border rounded border-gray-300 py-1 px-2 text-sm me-2 items-center">
          <i className='icon-compass me-1'/>
          <span className='me-1'>{ __('City') }</span>
        </div>
        <div className="flex border rounded border-gray-300 py-1 px-2 text-sm me-2 items-center">
          <i className='icon-location me-1'/>
          <span className='me-1'>{ __('Location') }</span>
        </div>
        <div className="flex border rounded border-gray-300 py-1 px-2 text-sm me-2 items-center">
          <i className='icon-power-cord me-1'/>
          <span className='me-1'>{ __('Type') }</span>
        </div>
      </div>

      <div
        onKeyPress={ handleEnterClick }
        className={ `filter-popup transition-opacity ease-in-out delay-50 duration-100 ${ showForm ? "active" : " h-0 my-0 opacity-0 hidden" }` }>
        <div className="flex flex-col w-full mx-auto min-w-64 max-w-[400px] px-2 overflow-y-auto">
          <div className="flex justify-between">
            <button className='flex justify-center items-center text-gray-500' onClick={ () => setShowForm(!showForm) }>
              <i className='icon-arrow-left2 text-xl py-8 pe-2 inline-flex rtl:rotate-180 rtl:ps-2'/>
              { __('Back') }
            </button>
          </div>
          <div className="flex w-full flex-col bg-white p-4 border rounded-lg my-2 text-sm ">
            <label htmlFor="TextFilter" className='text-bolder pb-2 text-gray-500'>{ __('Search') } : </label>
            <input type="text" placeholder={ __('Search On Text') } name='search' value={ form.text }
                   className='border w-full py-1 px-2 rounded'
                   autoComplete='address-level'
                   onKeyPress={ handleEnterClick }
                   autoFocus
                   onChange={ ({target}) => setValue({text: target.value}) }/>

          </div>

          <div className="flex w-full flex-col bg-white p-4 border rounded-lg my-2 text-sm ">
            <label htmlFor="CityFilter" className='text-bolder pb-2 text-gray-500'>{ __('City') } : </label>
            <select id='CityFilter' value={ form.city }
                    className='border w-full py-1 px-2 rounded placeholder-gray-600'
                    onChange={ ({target}) => setValue({city: target.value}) }
            >
              <option value={ '' }> { __('Select City') }</option>
              {
                cities.map((city, cityIndex) => (
                  <option value={ cityIndex } key={ `city-index-${ cityIndex }` }>{ city }</option>
                ))
              }
            </select>
          </div>

          <div className="flex w-full flex-col bg-white p-4 border rounded-lg my-2 text-sm ">
            <label htmlFor="TypeFilter" className='text-bolder pb-2 text-gray-500'>{ __('Charger Type') } : </label>
            <select id='TypeFilter' value={ form.type }
                    className='border w-full py-1 px-2 rounded placeholder-gray-600'
                    onChange={ ({target}) => setValue({type: target.value}) }
            >
              <option value={ '' }>{ __('Select Type') }</option>
              {
                types.map((type, typeIndex) => (
                  <option value={ typeIndex } key={ `type-index-${ typeIndex }` }>{ type }</option>
                ))
              }
            </select>
          </div>

          <div className="flex w-full flex-col bg-white p-4 border rounded-lg my-2 text-sm">
            <label htmlFor="LocationFilter" className='text-bolder pb-2 text-gray-500'>{ __('Location') }: </label>
            <button onClick={ setUserLocation }
                    disabled={ !!isFetching }
                    className='my-2 p-2 rounded bg-sky-600 text-white mb-3 flex justify-center items-center disabled:opacity-75'
                    id='LocationFilter'>
              <i className='icon-target me-2'/>
              <span className='me-2'>{ __('Get My Location') }</span>
              {
                !!isFetching &&
                <div
                  className="inline-block h-4 w-4 animate-spin rounded-full border-2 border-solid border-current border-e-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                  role="status">
                </div>
              }
            </button>

            <div className="flex gap-3">
              <input type="text" placeholder='Lat' name='lat' value={ form.lat }
                     disabled
                     className='border w-full py-1 px-2 rounded'
                     onChange={ ({target}) => setValue({lat: target.value}) }/>
              <input type="text" placeholder='Lng' name='lng' value={ form.lng }
                     disabled
                     className='border w-full py-1 px-2 rounded'
                     onChange={ ({target}) => setValue({lng: target.value}) }/>
            </div>

            <div className="flex gap-3 items-center my-2">

              <div className="flex w-full relative">
                <input type="number" placeholder={ __('Radius') } name='radius' value={ form.radius }
                       min={ 1 }
                       max={ 100 }
                       onInput={ validateRadius }
                       className='border w-full py-1 px-2 rounded focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none'
                       onChange={ ({target}) => setValue({radius: target.value}) }/>
                {
                  !!form?.radius &&
                  <button className='mx-2 w-2 absolute top-[22%] end-[10px]' onClick={ () => setValue({radius: ''}) }>
                    <i className='icon-cancel-circle text-gray-500'/>
                  </button>
                }

              </div>
              <span className='text-sm mx-2 font-bold text-gray-600'>
                              { __('KiloMeter') }
                          </span>
            </div>
          </div>

          <div className="flex w-full gap-3 bg-white py-4 rounded-lg my-2 text-sm max-xs:flex-wrap max-xs:gap-1 my-2">
            <button onClick={ searchFilter }
                    disabled={ !!isFetching }
                    className=' p-2 w-full rounded bg-emerald-600 text-white mb-3 flex justify-center items-center disabled:opacity-75'
                    id='SearchFilter'>
              <i className='icon-search me-2'/>
              <span className='me-2'>
                            { __('Search') }
                        </span>
              {
                isSearching &&
                <div
                  className="inline-block h-4 w-4 animate-spin rounded-full border-2 border-solid border-current border-e-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                  role="status">
                </div>
              }
            </button>

            <button onClick={ resetFilter }
                    disabled={ !!isFetching }
                    className='p-2 rounded w-full border border-gray-300 bg-white text-gray-900 mb-3 flex justify-center items-center disabled:opacity-75'
                    id='ResetFilter'>
              <i className='icon-cross me-2 text-xs'/>
              <span className='me-2'>
                            { __('Reset') }
                        </span>
            </button>
          </div>

        </div>
      </div>
    </div>
  )
};


const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

const mapStateToProps = ({appFilter}) => ({
  appFilter
});

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
