import Config from "library/config";
import React from 'react';
import {__} from 'library/langs.js'

const About = () => {
    const [appName] = React.useState(Config.PROJECT_NAME);
    const [supportEmail] = React.useState(Config.SUPPORT_EMAIL);
    const [supportPhone] = React.useState(Config.SUPPORT_PHONE);

    const ArVersion = () => (
        <div className='flex flex-col w-full my-10'>
            <h1 className="text-3xl font-bold text-center mb-4">{ appName }</h1>
            <div className="bg-white p-6 rounded-lg">
                <h2 className="text-xl font-semibold mb-4">من نحن</h2>
                <p className="mb-4">
                    مرحبًا بكم في <span className="font-bold">{ appName }</span>، مصدرك الأساسي لحلول شحن المركبات
                    الكهربائية في الأردن.
                    نحن متحمسون لثورة طريقة شحن المركبات الكهربائية في جميع أنحاء البلاد، مما يضمن تجربة سلسة وفعالة
                    لجميع أصحاب المركبات الكهربائية.
                </p>

                <h3 className="text-lg font-semibold mb-2">رسالتنا</h3>

                <p className="mb-4">
                    في <span className="font-bold">{ appName }</span>، رسالتنا هي تعزيز تجربة شحن المركبات الكهربائية في
                    الأردن من خلال توفير معلومات موثوقة وحلول مبتكرة.
                    نهدف إلى جعل امتلاك المركبات الكهربائية أكثر إمكانية وسهولة عن طريق توسيع شبكة محطات الشحن وتحسين
                    إمكانية الوصول إليها.
                </p>

                <h3 className="text-lg font-semibold mb-2">ماذا نفعل؟</h3>

                <ul className="list-disc pl-6 mb-4">
                    <li>
                        <span className="font-bold">محدد موقع محطات الشحن:</span> تتيح منصتنا للمستخدمين العثور بسهولة
                        على أقرب محطات الشحن في الأردن، مما يضمن لهم التخطيط لرحلاتهم بثقة.
                    </li>
                    <li>
                        <span className="font-bold">تعزيز الوصولية:</span> نتعاون مع الشركات والبلديات لتركيب محطات شحن
                        جديدة في مواقع استراتيجية، مما يجعل شحن المركبات الكهربائية أكثر سهولة للجميع.
                    </li>
                    <li>
                        <span className="font-bold">تحسين تجربة المستخدم:</span> من خلال التغذية الراجعة والتحسين
                        المستمر، نسعى لتحسين سهولة الاستخدام وموثوقية منصتنا والمحطات التي ندعمها.
                    </li>
                </ul>

                <h3 className="text-lg font-semibold mb-2">لماذا نختارنا؟</h3>

                <ul className="list-disc pl-6 mb-4">
                    <li>
                        <span className="font-bold">الخبرة المحلية:</span> مقرنا في الأردن، ولدينا فهم عميق للمشهد
                        والبنية التحتية المحلية، مما يمكننا من تخصيص خدماتنا لتلبية الاحتياجات الخاصة بأصحاب المركبات
                        الكهربائية الأردنيين.
                    </li>
                    <li>
                        <span className="font-bold">التزام بالجودة:</span> نحن ملتزمون بتقديم معلومات دقيقة ومحدثة
                        وخدمات موثوقة لضمان تجربة إيجابية لشحن المركبات الكهربائية.
                    </li>
                    <li>
                        <span className="font-bold">التركيز على العميل:</span> رضاؤكم هو أولويتنا. نحن هنا لدعمكم
                        والإجابة على أي أسئلة أو استفسارات قد تكون لديكم حول شحن المركبات الكهربائية في الأردن.
                    </li>
                </ul>

                <p>
                    <span className="font-bold">انضم إلينا لدفع التغيير</span><br/>
                    سواء كنت مالكًا لمركبة كهربائية، أو عمل تبحث عن دعم النقل المستدام، أو بلدية تهدف إلى تحسين بنية
                    تحتية مدينتك، ندعوك للانضمام إلينا في دفع التغيير نحو مستقبل أكثر صحة بيئيًا.
                </p>

                <p className="mt-4">
                    استكشف <span className="font-bold">{ appName }</span> اليوم واكتشف كيف يمكننا تمكينك من اعتماد
                    الحركة الكهربائية بثقة.
                </p>
            </div>
        </div>
    )

    const EnVersion = () => (
        <div className='flex flex-col w-full my-10'>
            <h1 className="text-3xl font-bold text-center mb-4">{ appName }</h1>
            <div className="bg-white p-6 rounded-lg">
                <h2 className="text-xl font-semibold mb-4">About Us</h2>
                <p className="mb-4">
                    Welcome to <span className="font-bold">{ appName }</span>, your go-to resource for EV charging
                    solutions in Jordan.
                    We are passionate about revolutionizing the way electric vehicles are charged across the
                    country, ensuring a
                    seamless and efficient experience for all EV owners.
                </p>

                <h3 className="text-lg font-semibold mb-2">Our Mission</h3>

                <p className="mb-4">
                    At <span className="font-bold">{ appName }</span>, our mission is to enhance the EV charging
                    experience in Jordan
                    by providing reliable information and innovative solutions. We aim to make electric vehicle
                    ownership more
                    accessible and convenient by expanding the network of charging stations and improving their
                    accessibility.
                </p>

                <h3 className="text-lg font-semibold mb-2">What We Do</h3>

                <ul className="list-disc pl-6 mb-4">
                    <li>
                        <span className="font-bold">Charging Station Locator:</span> Our platform allows users to
                        easily find the
                        nearest charging stations in Jordan, ensuring they can plan their journeys with confidence.
                    </li>
                    <li>
                        <span className="font-bold">Enhancing Accessibility:</span> We collaborate with businesses
                        and municipalities
                        to install new charging stations in strategic locations, making EV charging more convenient
                        for everyone.
                    </li>
                    <li>
                        <span className="font-bold">User Experience Improvement:</span> Through feedback and
                        continuous improvement,
                        we strive to optimize the usability and reliability of our platform and the charging
                        stations we support.
                    </li>
                </ul>

                <h3 className="text-lg font-semibold mb-2">Why Choose Us?</h3>

                <ul className="list-disc pl-6 mb-4">
                    <li>
                        <span className="font-bold">Local Expertise:</span> Based in Jordan, we have a deep
                        understanding of the local
                        landscape and infrastructure, allowing us to tailor our services to meet the specific needs
                        of Jordanian
                        EV owners.
                    </li>
                    <li>
                        <span className="font-bold">Commitment to Quality:</span> We are committed to providing
                        accurate, up-to-date
                        information and reliable services to ensure a positive EV charging experience.
                    </li>
                    <li>
                        <span className="font-bold">Customer Focus:</span> Your satisfaction is our priority. We are
                        here to support you
                        with any questions or concerns you may have about EV charging in Jordan.
                    </li>
                </ul>

                <p>
                    <span className="font-bold">Join Us in Driving Change</span><br/>
                    Whether you are an EV owner, a business looking to support sustainable transportation, or a
                    municipality aiming
                    to enhance your city’s infrastructure, we invite you to join us in driving change towards a
                    greener future
                </p>

                <p className="mt-4">
                    Explore <span className="font-bold">{ appName }</span> today and discover how we can empower you
                    to embrace electric
                    mobility with confidence.
                </p>
            </div>
        </div>
    )

    return (
        <div className='flex w-full flex-col'>
            {
                window.lang == 'ar' ? <ArVersion/> : <EnVersion/>
            }
            <div className="bg-white p-6 my-2 rounded-lg">
                {
                    !!supportEmail &&
                    <div className='flex my-2'>
                        <span className='text-sm text-gray-500'>{ __('Support Email') }</span>
                        <a href={ `mailto:${ supportEmail }` }
                           className='text-sm font-normal text-emerald-500 mx-2'>{ supportEmail }</a>
                    </div>
                }

                {
                    supportPhone &&
                    <div className='flex my-2 items-center'>
                        <span className='text-sm text-gray-500'>{ __('Support Phone Number') }</span>
                        <a href={ `tel:${ supportPhone }` }
                           dir={'ltr'}
                           className='text-sm font-normal text-emerald-500 mx-2'>{ supportPhone }</a>
                    </div>
                }
            </div>
        </div>
    )
        ;
};

export default About;
