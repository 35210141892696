import Storage from "./storage";
export default {
    settings: {
        dots: true,
        slidesToShow: 1,
        focusOnSelect: true,
        slidesToScroll: 1,
        variableWidth: false,
    },
    mapCardsSettings: {
        dots: false,
        slidesToShow: 2,
        focusOnSelect: true,
        slidesToScroll: 2,
        variableWidth: false,
        lazyLoad: false,
        infinite: false,
        arrows:false,
    },
    ImageSliderSetting: {
        dots: true,
        infinite: false,
        slidesToShow:3,
        variableWidth: false,
    }
}
