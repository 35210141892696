import React, {useEffect} from "react";
import {toast} from "react-toastify";
import Locations from "library/locations.js";
import {__} from 'library/langs.js';
import axios from "library/request.js";

const ReviewsForm = ({id, onHide}) => {
    const [form, setForm] = React.useState({title: '', description: '', rate: 0, ip_address: ''});
    const [isValid, setIsValid] = React.useState(false);

    const setValues = (value) => {
        setForm({
            ...form,
            ...value,
        })
    }

    useEffect(() => {
        Locations.getIpAddress().then(data=>{
            setForm({...data})
        })
    }, [])

    useEffect(() => {
        setIsValid(!!form?.rate && !!form?.title && !!form?.description)
    }, [form])

    const handleOnSubmit = () => {
        axios.post(`review/${ id }`, {id, ...form})
            .then((data) => {
                toast.success(data?.message || __('Saved Successfully'));
                onHide();
            })
            .catch((error) => {
                toast.error(error.errors || __('Something went wrong'));
            })
    }

    const handleOnCancel = () => {
        onHide();
    }

    return (
        <div className="relative">
            <div className="max-w-[400px] w-full my-3 fixed top-[15%] left-0 right-0 m-auto bottom-0 z-20">
                <div className="flex flex-col mx-auto max-w-[350px] bg-white rounded-2xl overflow-hidden shadow p-8">
                    <div className="flex">
                        <select name='rate'
                                value={ form.rate }
                                required
                                className='w-full my-3 px-2 py-2 border border-gray-200 rounded'
                                onChange={ ({target}) => setValues({rate: target.value}) }>
                            <option>{ __('Select Rate') }</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                        </select>
                    </div>
                    <div className="flex">
                        <input type="text" name='title'
                               value={ form.title }
                               required
                               maxLength={ 30 }
                               className='w-full my-3 px-2 py-2 border border-gray-200 rounded'
                               placeholder={ __('Title') }
                               onChange={ ({target}) => setValues({title: target.value}) }/>
                    </div>
                    <div className="flex">
                          <textarea name='description' value={ form.description }
                                    rows={ 6 }
                                    required
                                    maxLength={ 200 }
                                    placeholder={ __('Review') }
                                    className='w-full my-3 px-2 py-2 border border-gray-200 rounded'
                                    onChange={ ({target}) => setValues({description: target.value}) }>
                          </textarea>
                    </div>

                    <div className="flex gap-2 w-full mt-2">
                        <button className='w-full bg-emerald-600 rounded text-white p-2 disabled:opacity-50'
                                disabled={!isValid}
                                onClick={ handleOnSubmit }
                        >
                            { __('Submit') }
                        </button>
                        <button className='w-full bg-white border border-gray-200 rounded'
                                onClick={ handleOnCancel }
                        >
                            { __('Cancel') }
                        </button>
                    </div>
                </div>
            </div>
            <div className="bg-black bg-opacity-25 top-0 left-0 fixed w-screen h-screen z-10"/>
        </div>
    )
};


export default ReviewsForm
