import React from "react";
import {Link} from "react-router-dom";
import ChargerCard from 'components/ChargerCard';
import favorites from "library/favorites.js";
import {__} from 'library/langs.js';
import axios from "library/request";
import Config from "library/config";

const FavoriteList = () => {

    const [list, setList] = React.useState([]);
    const [cities, setCities] = React.useState([]);
    const [types, setTypes] = React.useState([]);
    const [filter, setFilter] = React.useState({});
    const [page, setPage] = React.useState(1);
    const [meta, setMeta] = React.useState(null);
    const [fetching, setFetching] = React.useState(true);
    const [appName] = React.useState(Config.PROJECT_NAME);

    const fetchAll = () => {
        setFetching(true);
        const params = {
            ...filter,
            page,
        }
        return axios.get('/charger/listByIds', {params})
            .then(data => {
                setList(data.result?.list || []);
                setCities(data.result.cities || []);
                setTypes(data.result.types || []);
                setMeta(data.meta);
                setFetching(false);
            }).catch(error => {
                setList([]);
                setFetching(false);
            })
    }

    React.useEffect(() => {
        fetchAll();
    }, [page, filter]);

    React.useEffect(() => {
        setFilter({
            ids: favorites.getUserListIds()
        })
    }, []);

    return (
        <div className='flex flex-col'>
            <div className="flex flex-col gap-4 my-5 w-fit w-full mx-auto px-4 justify-center items-center">
                {
                    !fetching && list.map((item, index) => (
                        <ChargerCard key={ `charger-card-${ index }` }
                                     types={ types }
                                     index={ index }
                                     urlPath={ `/charger/${ item.id }` }
                                     onDeleteCallBack={ () => fetchAll() } item={ item } cities={ cities }
                        />
                    ))
                }

                {
                    !fetching && !list?.length &&
                    <div className="flex flex-col items-center justify-center mt-20 py-16 flex-grow max-w-xs">
                        <div className="text-center p-4">
                            <h1 className="text-2xl font-bold text-gray-800 mb-4 leading-9">{ __('No chargers added to favorites yet') }.</h1>
                            <p className="text-gray-600 mb-4 text-sm">
                                {
                                    __("Explore :appName to add chargers to favorites or help us create new chargers", {appName})
                                }.
                            </p>
                            <Link to="/"
                                  className="text-xs inline-block bg-emerald-600 hover:bg-emerald-800 text-white py-2 px-4 rounded-md mb-4">
                                { __('Set Chargers in Favorites') }
                            </Link>
                        </div>
                    </div>
                }
            </div>
            {
                !fetching && meta?.total_pages > 1 &&
                <div
                    className="max-sm:w-96 max-w-[450px] flex justify-between item-center w-full px-3 my-3 text-sm text-sky-600 mx-auto">
                    <button className='py-3  w-1/2 disabled:opacity-50 justify-center'
                            onClick={ () => setPage(1 + page) }
                            disabled={ !meta.next_page }>
                        <div className="w-full flex justify-start items-center">
                            <i className='icon-arrow-left2 rtl:rotate-180'/>
                            <span className='mx-2'> { __('Next') }</span>
                        </div>
                    </button>
                    <button className='py-3 w-1/2 disabled:opacity-50 flex justify-center items-center'
                            onClick={ () => setPage(page - 1) }
                            disabled={ !meta.prev_page }>
                        <div className="w-full flex justify-end items-center">
                            <span className='mx-2'>{ __('Prev') }</span>
                            <i className='icon-arrow-right2 rtl:rotate-180'/>
                        </div>
                    </button>
                </div>
            }

            {
                fetching &&
                <div
                    className="flex p-8 rounded-xl bg-white bg-opacity-25 fixed flex-col justify-center items-center m-auto left-0 right-0 top-0 bottom-0 w-64 h-64">
                    <div className="relative">
                        <div className="w-12 h-12 bg-emerald-500 rounded-full bg-opacity-50"/>
                        <div
                            className="w-12 h-12 bg-emerald-500 rounded-full absolute top-0 left-0 animate-ping bg-opacity-50"/>
                        <div className="w-12 h-12 bg-emerald-500 rounded-full absolute top-0 left-0 animate-pulse"/>
                    </div>
                </div>
            }

        </div>
    );
};

export default FavoriteList;
